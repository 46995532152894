.recipe-filters {
    $self: &;
    &__panel {
        width: 100%;
        position: absolute;
        top: calc(100% - 5px);
        right: 0;
        z-index: 30;
        max-height: 0px;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        opacity: 0;

        &_opened {
            max-height: 2000px;
            opacity: 1;
        }

        @include breakpoint(small) {
            width: 497px;
        }

        &-container {
            border: $border-width solid $black;
            background: $white;
            padding: 0 17px;

            #{$self}__panel_has-selected & {
                padding-top: 22px;

                @include breakpoint(medium) {
                    padding-top: 31px;
                }
            }

            @include breakpoint(medium) {
                padding: 37px;
            }
        }
    }

    &__filter {
        border-bottom: $border-width solid $black;

        &:first-child {
            border-top: none;

            #{$self}__panel_has-selected & {
                border-top: $border-width solid $black;
            }
        }

        &:last-child {
            border-bottom: none;

            #{$self}__panel_has-selected & {
                border-bottom: $border-width solid $black;
            }
        }
    }

    &__clear-filters-button {
        cursor: pointer;
        @include clear-styles;
        text-decoration: underline;
        @include font-size(14px, 16px);
        display: inline-flex;
        margin: 0 0 32px;

        @include breakpoint(medium) {
            margin-bottom: 43px;
            @include font-size(16px, 16px);
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &_bottom {
            margin: 29px 0 34px;
        }
    }

    &__selected-filters-heading {
        display: block;
        font-family: $font-family-secondary;
        margin: 0 0 28px;
        @include font-size(20px, 20px);

        @include breakpoint(medium) {
            @include font-size(30px, 30px);
            margin-bottom: 36px;
        }
    }

    &__selected-filters {
        margin: 0 0 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        @include breakpoint(medium) {
            margin-bottom: 27px;
        }
    }

    &__remove-filter-button {
        @include flex-button;
        @include font-size(14px, 16px);
        margin: 0 15px 14px 0;

        @include breakpoint(medium) {
            @include font-size(16px, 16px);
            margin: 0 22px 9px 0;
        }

        &-icon {
            margin: 0 7px 0 0;
            transform: rotate(45deg);
            display: block;
            width: 14px;
            color: $black;
            opacity: 0.5;
        }

        &-text {
            color: $black;
        }
    }
}