$block-spacing: 105px;
$left-side-width: calc(50% + #{$block-spacing} + 20px);

.text-with-image-block {
    $self: &;
    @include container;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @include breakpoint(medium) {
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 100px;
    }

    @include breakpoint(1690px, "min"){
        margin-bottom: 160px;
    }

    &__text-container,
    &__image-container {
        @include breakpoint(medium) {
            width: 50%;
            align-self: center;
        }
    }

    &__text-container {
        @include breakpoint(medium) {
            #{$self}_image-position_left & {
               order: 2;
            }
            #{$self}_image-position_right &{
                order: 1;
                padding-right: calc(#{$block-spacing}/2);
                flex: 0 0 calc(#{$left-side-width} - #{$block-spacing}/2);
            }
        }

        @include breakpoint(1690px, "min"){
            #{$self}_image-position_right &{
                padding-left: $block-spacing;
                padding-right: calc(#{$block-spacing} + 20px);
                flex: 0 0 $left-side-width;
            }
        }
    }

    &__image-container {
        margin: 0 0 20px;
        @include breakpoint(medium){
            margin: 0;
            #{$self}_image-position_left & {
                order: 1;
                padding-right: calc(#{$block-spacing}/2);
                flex: 0 0 calc(#{$left-side-width} - #{$block-spacing}/2);
             }
            #{$self}_image-position_right &{
                order: 2;
            }
        }
        @include breakpoint(1690px, "min"){
            #{$self}_image-position_left &{
                padding-right: $block-spacing;
                flex: 0 0 $left-side-width;
                width: $left-side-width;
            }
        }
    }
    
    &__heading{
        @include font-size(32px, 38px);
        margin: 0 0 20px;

        @include breakpoint(medium){
            margin-bottom: 37px;
            @include font-size(50px, 60px);
        }
    }

}
