.technique-navigation {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;

    @include breakpoint(medium){
        flex-direction: column;
        margin-left: -14px;
        margin-bottom: -18px;
    }

    &__item{
        align-self: flex-start;
        padding: 3px 0;
        margin: 0 20px 10px 0;

        &_active{
            background: $black;
            padding-left: 14px;
            padding-right: 14px;
        }

        @include breakpoint(medium){
            padding: 8px 14px 9px;
            margin: 0 0 10px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    &__link{
        @include responsive-font-size(16px, 22px);
        line-height: 1;
        text-decoration: none;
        color: $black;
        opacity: 0.5;
        font-weight: normal;
        transition: all 0.3s ease-in-out;
        outline: none;
        
        &:not(#{$self}__link_active){
        &:hover,
        &:focus {
            opacity: 1;
            text-decoration: underline;
        }
        }

        &_active{
            color: $white;
            opacity: 1;
            cursor: default;
        }
    }
}