$toggle-icon-size: 23px;
$toggle-icon-size-mobile: 14px;

.accordion {
    &__item {
        border-bottom: 2px solid $black;

        &:first-child {
            border-top: 2px solid $black;
        }
    }

    &__heading {
        position: relative;
    }

    &__button {
        padding: 10px 55px 10px 0;
        cursor: pointer;

        @include breakpoint(medium) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &[aria-expanded=true] {
            &:before {
                transform: rotate(45deg) translateY(-48%);
                transform-origin: top;

                @include breakpoint(medium) {
                    transform: rotate(45deg) translateY(-45%);
                }
            }
            &:after {
                transform: rotate(45deg);
            }
        }

        &:before {
            position: absolute;
            top: 50%;
            right: 6px;
            margin-top: 1px;
            content: ' ';
            height: $toggle-icon-size-mobile;
            transform: translateY(-50%);
            width: 2px;
            background-color: $black;

            @include breakpoint(medium) {
                height: $toggle-icon-size;
                right: 30px;
                margin-top: 0;
            }
        }

        &:after {
            position: absolute;
            top: 50%;
            right: 0;
            content: ' ';
            height: 2px;
            width: $toggle-icon-size-mobile;
            background-color: $black;

            @include breakpoint(medium) {
                width: $toggle-icon-size;
                right: 20px;
            }
        }
    }

    &__panel {
        padding: 10px 0 15px;
        @include breakpoint(medium) {
            padding: 10px 0 35px;
        }
    }
}