.sources-and-links-block {
    margin-bottom: 40px;

    @include breakpoint(medium){
        margin-bottom: 60px;
    }

    &__container {
        position: relative;
        background-color: rgba($light-orange, 0.5);
        padding: 30px;
        color: $black;

        @include breakpoint(medium) {
            padding: 80px 60px;
        }
    }

    &__heading {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(7deg);
        background-color: $coral;
        font-weight: 600;
        padding: 10px;
        @include font-size(16px, 16px);

        @include breakpoint(medium) {
            @include font-size(24px, 24px);
		}
    }
}