.technique-steps-carousel {
    &__item {
        display: flex !important;
        flex-direction: column;
        background-color: #f7f4ec;
        outline: none;

        @include breakpoint(small) {
            flex-direction: row;
        }

        &-counter {
            color: $black;
            &-label {
                color: $black;
                opacity: 0.5;
                margin: 0 0 -5px;
                @include font-size(12px, 24px);

                @include breakpoint(medium) {
                    @include font-size(14px, 16px);
                    margin: 0 0 10px;
                }
            }

            &-numbers {
                @include font-size(34px, 40px);
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: nowrap;

                @include breakpoint(medium, "max") {
                    padding-bottom: 3px;
                }
            }

            &-number:last-child {
                display: flex;
                flex-wrap: nowrap;

                &:before {
                    content: "";
                    border-left: $border-width solid $black;
                    align-self: center;
                    height: 29px;
                    transform: rotate(16deg);
                    margin: -2px 6px 0 9px;
                    display: block;

                    @include breakpoint(medium) {
                        margin: -2px 9px 0 12px;
                    }
                }
            }
        }

        &-navigation {
            margin-top: auto;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            @include breakpoint(small, "max") {
                order: 1;
                margin-bottom: 40px;
            }
        }

        &-controls {
            display: flex;
        }

        &-button {
            @include clear-styles;
            align-self: flex-end;
            border: $border-width solid $black;
            color: $black;
            height: 46px;
            padding: 0 14px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            @include breakpoint(large) {
                height: 57px;
                padding: 0 20px;
            }

            &:hover,
            &:focus {
                background: $black;
                color: $white;
            }

            &_prev {
                margin: 0 8px 0 auto;

                @include breakpoint(large) {
                    margin-right: 10px;
                }
            }

            &-icon {
                display: block;
                width: 22px;
                height: 15px;

                @include breakpoint(large) {
                    width: 25px;
                    height: 20px;
                }
            }
        }

        &-text-content {
            padding: 25px 30px 55px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include breakpoint(small) {
                flex: 0 0 47.5%;
                width: 47.5%;
            }

            @include breakpoint(medium) {
                padding: 60px;
            }
            @include breakpoint(small, "max") {
                order: 2;
            }
        }

        &-heading {
            margin: 0 0 16px;
            @include font-size(32px, 38px);

            @include breakpoint(large) {
                margin-bottom: 25px;
                @include font-size(50px, 60px);
            }
            @include breakpoint(small, "max") {
                order: 2;
            }
        }

        &-intro {
            margin-bottom: auto;
            padding-bottom: 40px;

            @include breakpoint(small, "max") {
                order: 3;
                padding-bottom: 0;
            }
        }

        &-media {
            position: relative;
            &:before {
                content: "";
                display: block;
                width: 100%;
                padding: 81% 0 0;
                position: relative;
                z-index: 0;

                @include breakpoint(small) {
                    padding-top: 100%;
                }
            }
           
            @include breakpoint(small, "max") {
                order: 1;
            }
            @include breakpoint(small) {
                flex: 0 0 52.5%;
                width: 52.5%;
                order: 2;
            }
        }
    }

    /* Slider */
    .slick-slider {
        position: relative;

        display: block;
        box-sizing: border-box;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;

        display: block;
        overflow: hidden;

        margin: 0;
        padding: 0;
    }
    .slick-list:focus {
        outline: none;
    }
    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;

        display: flex !important;
    }
    .slick-track:before,
    .slick-track:after {
        display: table;

        content: "";
    }
    .slick-track:after {
        clear: both;
    }
    .slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        display: none;
        float: left;

        height: inherit !important;
        min-height: 1px;

        & > div{
            display: flex;
            height: 100%;
        }
    }
    [dir="rtl"] .slick-slide {
        float: right;
    }
    .slick-slide img {
        display: block;
    }
    .slick-slide.slick-loading img {
        display: none;
    }
    .slick-slide.dragging img {
        pointer-events: none;
    }
    .slick-initialized .slick-slide {
        display: block;
    }
    .slick-loading .slick-slide {
        visibility: hidden;
    }
    .slick-vertical .slick-slide {
        display: block;

        height: auto;

        border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
        display: none;
    }
}
