.teaser {
    $self: &;
    height: 100%;

    @include breakpoint(small) {
        min-height: 38vw;
    }

    @include breakpoint($font-size-scaling-breakpoint) {
        min-height: 625px;
    }
    

    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;

        @include breakpoint(small) {
            .teaser_horizontal & {
                flex-direction: row;
            }
        }
    }

    &__media {
        position: relative;
        overflow: hidden;

        .teaser_horizontal & {
            @include breakpoint(small) {
                width: 50%;
            }
        }

        .teaser_horizontal:not(.teaser_full-width) & {
            @include breakpoint(small) {
                width: 45%;
                height: auto;
            }

            @include breakpoint(medium) {
                width: 50%;
            }
        }
    }

    &__content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        padding: 20px 32px 30px;

       

        .teaser_without-image &, .teaser_vertical & {
            text-align: center;
        }

        @include breakpoint(small) {
            padding: 2vw 3vw 2.8vw;

            .teaser_horizontal & {
                width: 50%;
            }   
            
            .teaser_full-width & {
                padding: 2vw 5.4vw 2.8vw;
            }
        }

        @include breakpoint(medium) {
            padding: 2vw 2.4vw 2.8vw;

            .teaser_full-width & {
                padding: 2vw 3.4vw 2.8vw;
            }
        }

        @include breakpoint($font-size-scaling-breakpoint) {
            padding: 30px 40px 48px;

            .teaser_full-width & {
                padding: 70px 80px;
            }
        }

        #{$self}_in-two-columns-view &{
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__heading {
        @include font-size(40px, 45px);
        margin: 0;
        word-break: break-word;

        @include breakpoint(small) {
            font-size: 3vw;
            line-height: 1.2;
        }
        
        @include breakpoint($font-size-scaling-breakpoint) {
            @include font-size(50px, 60px);
        }

        &_large {
            @include breakpoint(small) {
                font-size: 4.5vw;
                line-height: 1.2;
            }
    
            @include breakpoint($font-size-scaling-breakpoint) {
                @include font-size(75px, 85px);
            }
        }

        #{$self}_in-two-columns-view &{
            @include font-size(20px, 30px);
        }
    }

    &__image {
        transition: transform 300ms;
    }

    &__link {
        text-decoration: none;

        &:hover {
			#{$self}__image {
				@include image-hover-zoom;
			}
            #{$self}__heading {
                text-decoration: underline;
            }
		}
    }

    &__text {
        &, &:last-child {
            margin: 20px 0 0;
        }

        @include breakpoint(small) {
            &, &:last-child {
                margin: 1.4vw 0 0;
            }
        }

        @include breakpoint($font-size-scaling-breakpoint) {
            &, &:last-child {
                margin: 20px 0 0;
            }
        }

        #{$self}_in-two-columns-view &{
            display: none;
        }
    }

    &__categories {
        list-style: none;
        @include font-size(12px, 16px);
        margin-bottom: 12px;

        @include breakpoint(small) {
            font-size: 1.4vw;
            line-height: 1.3;
            margin-bottom: 1vw;
        }

        @include breakpoint(medium) {
            font-size: 1vw;
            margin-bottom: 0.6vw;
        }

        @include breakpoint($font-size-scaling-breakpoint) {
            @include font-size(14px, 16px);
            margin-bottom: 12px;
        }

        &-item {
            display: inline;
            color: $dark-gray;

            &:not(:last-child) {
                &:after {
                    content: '/';
                    margin: 0 6px;
                }
            }
        }
    }
}