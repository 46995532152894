@import "../../shared-components-styles/rich-text-common.scss";
@import "../../shared-components-styles/underlined-heading.scss";
.rich-text {
  color: $black;
  @include font-size(16px, 26px);

  @include breakpoint(medium) {
    @include font-size(22px, 36px);
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  blockquote {
    @include font-size(28px, 33px);
    font-family: $font-family-secondary;
    margin: 40px 0;

    @include breakpoint(medium) {
      @include font-size(50px, 60px);
      margin: 60px 0;
    }

    &:before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }
  }

  ul{
    @include bullet-list;
  }

  ol{
    @include ordered-list;
  }

  &_in-recipe {
    h2 {
      @extend .underlined-heading;
    }

    ul li {
      @include breakpoint(large) {
        padding-right: 85px;
      }
    }
  }

  &_in-sources-and-links {
    @include font-size(16px, 26px);
    word-wrap: break-word;

    @include breakpoint(medium) {
      @include font-size(22px, 36px);
    }

    p:not(:last-child) {
        margin-bottom: 20px;
    }   
  }
}
