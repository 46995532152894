@import "../../shared-components-styles/underlined-heading.scss";
.recipe-page {
  @media print {
    padding: 20px 40px;
  }
  &__meta-panel,
  &__columns {
    @include container;
    margin-bottom: 40px;
    max-width: $max-width-narrow;

    @include breakpoint(medium) {
      margin-bottom: 60px;
    }
  }
  &__meta-panel {
    @media print {
      display: block!important;
    }
  }
  &__intro {
    @include container;
    margin-bottom: 97px;
    max-width: $max-width-narrow;
    color: $black;
    @media print {
      display: none;
    }
    &-text {
      max-width: $max-text-width;
    }
  }

  &__meta-panel {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    @media print {
      padding: 0;
    }
    @include breakpoint(medium) {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__categories {
    margin-bottom: 10px;
    @media print {
      display: none;
    }
    @include breakpoint(medium) {
      margin-bottom: 0;
      margin-bottom: -20px;
    }
  }

  &__details {
    @media print {
      float: right;
      font-size: 24px;
    }

    @include breakpoint(medium) {
      margin-left: auto;
      align-self: flex-start;
      padding: 11px 0 0 40px;
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    @media print {
      flex-direction: row;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }
  }

  &__column {
    &:first-child {
      @include breakpoint(medium) {
        flex: 0 0 275px;
        margin-right: 80px;
      }
    }
    &:last-child {
      @include breakpoint(medium) {
        margin-left: auto;
        flex: 0 0 calc(100% - 355px);
        max-width: 905px;
      }
    }

    @media print {
      &:first-child {
        width: 40%;
        padding-right: 3%;
      }
      &:last-child {
        width: 60%;
        max-width: none;
      }
    }
  }

  &__body-heading{
    @extend .underlined-heading;
  }

  &__share-and-rate-recipe-panel {
    margin-bottom: 60px;
    margin-top: 60px;

    @include breakpoint(medium) {
        margin-top: 90px;
    }
  }
}
