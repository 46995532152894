.quick-search {
    display: flex;
    flex-wrap: nowrap;
    max-width: 345px;
    width: 25vw;

    $self: &;

    &__wrapper {
        #{$self}_active & {
            @include breakpoint(medium, "max") {
                @include full-size;
                position: fixed;
                z-index: 23;
                background: rgba(255,255,255, 0.5);
            }         
        }
    }

    &__container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center; 

        #{$self}_active & {
            @include breakpoint(medium, "max") {
                padding: 30px 20px;
                background: $white;
            }         
        }
    }

    &__form-holder {
        display: none;
        flex: 1;

        @include breakpoint(medium) {
            display: flex;
        }

        #{$self}_active & {
            @include breakpoint(medium, "max") {
                display: flex;
            }         
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 5px;
        background: none;
        font-size: 26px;
        color: $medium-gray;
        cursor: pointer;

        &_mobile {
            display: flex;
            @include breakpoint(medium) {
                display: none;
            }
        }

        &_desktop {
            display: none;
            @include breakpoint(medium) {
                display: flex;
            }
        }
    }

    &__icon {
        width: 24px;
        height: 28px;
    }

    &__label-btn {
        margin: 0 7px 0 -5px;
        flex: 0 0 35px;

        #{$self}_active & {
            @include breakpoint(medium, "max") {
                position: fixed;
                top: 36px;
                left: 20px;
                z-index: 24;
            }         
        }
    }
}