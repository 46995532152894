.share-panel {
    margin-bottom: 30px;
    @media print {
        display: none;
    }

    &__container {
        position: relative;
    }

    &__heading {
        display: block;
        @include font-size(14px, 16px);
        color: rgba($black, 0.5);
        margin-bottom: 20px;
    }

    &__list {
        list-style: none;
    }

    &__item {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 10px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    &__button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: inherit;
        border: none;
        width: 70px;
        height: 70px;
        background-color: $light-beige;
        cursor: pointer;

        &:hover,
		&:focus,
		.mouse-user &:focus:hover {
            background-color: $beige;
            border: 2px solid $black;
		}
    }

    &__icon {
        max-width: 22px;
        max-height: 20px;
    }

    &__link-copied-message {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        padding-top: 5px;
        @include font-size(16px, 20px);
        color: rgba($black, 0.7);
        opacity: 0;
        transition: all 250ms;

        &_visible {
            opacity: 1;
        }
    }
}