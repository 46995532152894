.area-block {
    padding: 25px 20px 35px;
    color: $black;
    background-color: $light-coral;
    margin-bottom: 40px;

    @include breakpoint(medium){
        margin-bottom: 60px;
        padding: 60px;
    }

    @include breakpoint(large){
        padding: 100px;
    }

    &__heading {
        margin: 0 0 25px;
        @extend .h4;
    }

    &__text {
        border-top: $border-width solid $black;
        padding-top: 15px;
    }
}