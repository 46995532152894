.header {
    position: relative;
    z-index: 101;
    @extend %clear;

    &__container {
        display: flex;
        align-items: center;
        @extend .container;
        position: relative;
        z-index: 100;
        overflow: visible;
        @extend %clear;
        height: $header-height;

        @include breakpoint(medium) {
            height: $header-height-medium;
        }

        @media (max-height: 800px) and (min-width: 768px) {
            height: $header-height;
		}

        @media print {
            height: auto;
            margin-bottom: 20px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__logo {
        max-width: 165px;
        max-height: 53px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 11;

        @include breakpoint(medium) {
            max-width: 250px;
            max-height: 80px;
            z-index: 30;
        }
        
        @media (max-height: 800px) and (min-width: 767px) {
            max-width: 165px;
            max-height: 53px;
        }

        @media print {
            position: static;
            transform: none;
            margin-left: auto;
            max-width: 140px;
        }
    }

    &__quick-search {
        @media print {
            display: none!important;
        }
    }
}
