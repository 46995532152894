.theme-page{
  &__navigation-tags,
  &__intro{
    @include container;
  }

  &__navigation-tags{
    padding-top: 13px;
    padding-bottom: 17px;

    @include breakpoint(medium){
      padding-top: 25px;
      padding-bottom: 39px;
    }
  }

  &__intro{
    margin-bottom: 17px;
    color: $black;
    @include breakpoint(medium){
      margin-bottom: 100px;
    }
  }

  &__intro-text{
    @include breakpoint(medium){
      max-width: $max-text-width;
    }
  }
}