@font-face {
    src: url("../assets/fonts/Roslindale/Roslindale-DisplayCondensedItalic.woff2") format("woff2"),
        url("../assets/fonts/Roslindale/Roslindale-DisplayCondensedItalic.woff") format("woff");
    font-family: "Roslindale";
    font-style: italic;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    src: url("../assets/fonts/Roslindale/Roslindale-DisplayCondensedRegular.woff2") format("woff2"),
        url("../assets/fonts/Roslindale/Roslindale-DisplayCondensedRegular.woff") format("woff");
    font-family: "Roslindale";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}


@font-face {
    src: url("../assets/fonts/BananaGroteskt/BananaGrotesk-Regular.woff2") format("woff2"),
        url("../assets/fonts/BananaGroteskt/BananaGrotesk-Regular.woff") format("woff");
    font-family: "Banana Groteskt";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    src: url("../assets/fonts/BananaGroteskt/BananaGrotesk-Medium.woff2") format("woff2"),
        url("../assets/fonts/BananaGroteskt/BananaGrotesk-Medium.woff") format("woff");
    font-family: "Banana Groteskt";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    src: url("../assets/fonts/BananaGroteskt/BananaGrotesk-Bold.woff2") format("woff2"),
        url("../assets/fonts/BananaGroteskt/BananaGrotesk-Bold.woff") format("woff");
    font-family: "Banana Groteskt";
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}
