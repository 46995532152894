.search-page {
    &__search-form{
        max-width: $max-text-width;
        margin: 0 0 40px;
        @include breakpoint(medium){
            margin-bottom: 71px;
        }
    }
    &__hits-text{
        @include font-size(20px, 28px);
        margin: 0 0 37px;
        min-height: 28px;
        color: $black;
        word-break: break-word;

        @include breakpoint(medium){
            @include font-size(32px, 42px);
            margin-bottom: 47px;
            min-height: 42px;
        }
    }

    &__container{
        @include container;
        position: relative;
        z-index: 5;
    }

    &__content{
        position: relative;
        min-height: 50px;
    }

    &__no-results{
        &-heading{
            @extend .recipes-overview-page__no-results-heading;
            margin-top: 20px;
        }
        &-text{
            @extend .recipes-overview-page__no-results-text;
        }
    }
}