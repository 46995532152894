.image {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  &_fluid {
    @include fluid-image();
  }

  &__img {
    display: block;
    width: 100%;
  }

  &_size {
    &_header-logo {
      @include original-proportions-image(250, 80);
    }
    &_top-banner-image {
      @include fluid-image();
    }
    &_top-image {
      @include fixed-proportion-image(375, 255);
      @include breakpoint(small) {
        @include fixed-proportion-image(1640, 810);
      }
    }
    &_top-image-half {
      @include fixed-proportion-image(375, 255);
      @include breakpoint(small) {
        @include fixed-proportion-image(820, 810);
      }
    }
    &_top-image-article {
      @include fixed-proportion-image(338, 223);
      @include breakpoint(small) {
        @include fixed-proportion-image(1640, 810);
      }
    }
    &_top-image-half-article {
      @include fixed-proportion-image(338, 223);
      @include breakpoint(small) {
        @include fixed-proportion-image(820, 810);
      }
    }
    &_recipe-teaser,
    &_search-teaser{
      @include  fixed-proportion-image(300, 180);

      @include breakpoint(medium) {
        @include  fixed-proportion-image(380, 280);
      }
    }

    &_weekly-menu-side-panel-logo{
      @include original-proportions-image(146, 146);
    }
    &_teaser-horizontal {
      @include fixed-proportion-image(374, 210);
      @include breakpoint(small) {
        @include fluid-image();
        height: auto;
      }
    }
    &_teaser-vertical {
      @include fixed-proportion-image(374, 210);
      @include breakpoint(small) {
        @include fixed-proportion-image(281, 181);
      }
      @include breakpoint(large) {
        @include fixed-proportion-image(525, 392);
      }
    }

    &_text-with-image-block {
      @include original-proportions-image(840, 526);
    }

    &_video-block {
      @include fixed-proportion-image(800, 338);
    }

    &_technique-steps-carousel{
      @include fixed-proportion-image(338, 273);
      @include breakpoint(small) {
        @include fixed-proportion-image(663, 663);
      }
    }
  }
}
