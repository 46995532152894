.footer {
    $self: &;
    @include container;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 70px;
    padding-bottom: $spacing-xlarge * 2;

    @include breakpoint(large) {
        padding-top: $spacing-xxlarge;
        padding-bottom: 180px;
    }

    @include breakpoint(small-max, "max") {
        flex-wrap: wrap;
        padding-right: 48px;
        padding-left: 48px;
    }

    @include breakpoint(375px, "max") {
        padding-right: 20px;
        padding-left: 20px;
    }

    &__heading {
        display: block;
        margin: 0 0 3px;
        font-weight: normal;
        font-family: $font-family;
        @include font-size(14px, 22px);
        color: $dark-blue;

        @include breakpoint(small) {
            margin: 0 0 14px;
            @include font-size(20px, 22px);
        }
    }

    &__column {
        padding-right: 20px;
        overflow: hidden;
        word-break: break-word;

        @include breakpoint(medium) {
            flex: 0 0 25%;
            width: 25%;
        }

        &:nth-child(1) {
            @include breakpoint(small-max, "max") {
                flex: 0 0 auto;
                order: 1;
                margin-right: auto;
                max-width: 52%;
                width: 52%;
                margin-bottom: 35px;
                padding-right: 0;
            }

            @include breakpoint(large) {
                max-width: 315px;
            }
        }

        &:nth-child(2) {
            @include breakpoint(small-max, "max") {
                flex: 0 0 50%;
                max-width: 50%;
                order: 3;
                margin-right: auto;
            }

            @include breakpoint(large) {
                max-width: 315px;
            }
        }

        &:nth-child(3) {
            margin-left: auto;

            @include breakpoint(large) {
                max-width: 275px;
            }

            @include breakpoint(small-max, "max") {
                flex: 0 0 50%;
                max-width: 50%;
                padding-right: 0;
                order: 4;
            }
        }

        &:nth-child(4) {
            display: flex;
            flex-direction: column;

            @include breakpoint(large) {
                margin-left: 145px;
                width: auto;
                flex: 0 0 auto;
            }

            @include breakpoint(small-max, "max") {
                flex: 0 0 auto;
                margin-left: 0px;
                padding-right: 0;
                order: 2;
                margin-bottom: $spacing-large;
                overflow: hidden;
                max-width: 48%;
                overflow:hidden;
            }
        }
    }

    &__main-menu {
        list-style: none;
        padding-top: 12px;

        @include breakpoint(small) {
            padding-top: 5px;
        }

        &-item {
            margin: 0 0 10px;
            
            @include breakpoint(small) {
                margin: 0 0 10px;
            }

            &:last-child {
                margin: 0;
            }
        }

        &-link {
            text-decoration: none;
            color: $dark-blue;
            font-family: $font-family-secondary;
            @include font-size(30px, 40px);
            word-break: break-word;

            @include breakpoint(small) {
                @include font-size(40px, 50px);
            }

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    &__menu {
        margin-bottom: 9px;
        list-style: none;

        @include breakpoint(small) {
            padding-top: $border-width;
            margin-bottom: 27px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-item {
            @include font-size(14px, 24px);

            @include breakpoint(small) {
                @include font-size(20px, 28px);
            }
        }

        &-link {
            text-decoration: underline;
            color: $dark-blue;
            font-family: $font-family;
            word-break: break-word;

            &:hover,
            &:focus{
                text-decoration: underline;
            }

            @include breakpoint(small) {
                text-decoration: none;
            }
        }
    }

    &__to-top-button {
        margin: 0 0 $spacing-xlarge;
        align-self: flex-end;

        @include breakpoint(large) {
            margin-bottom: 70px;
        }

        @include breakpoint(small-max, "max") {
            text-decoration: underline;
        }
    }

    &__logo {
        max-width: 100px;
        max-height: 100px;
        clear: both;
        align-self: flex-end;

        @include breakpoint(large) {
            max-width: 165px;
            max-height: 165px;
        }
    }
}
