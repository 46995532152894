.breadcrumbs {
    @include container;
    min-height: $breadcrumbs-height-mobile;
    @include breakpoint(medium) {
        min-height: $breadcrumbs-height;
    }

    @media print {
        display: none;
    }

    &__items {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        padding: 18px 0 9px;
        margin: 0;

        @include breakpoint(medium) {
            padding: 29px 0 25px;
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        color: $dark-blue;
        @include font-size(12px, 16px);
        margin: 0 0 5px;
        @include breakpoint(medium) {
            @include font-size(14px, 16px);
        }

        &:after {
            content: "/";
            display: block;
            margin: 0 6px 0 5px;
            font-size: inherit;
        }

        &:last-child {
            pointer-events: none;
            &:after {
                display: none;
            }
        }
    }

    &__link {
        text-decoration: underline;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        &:hover,
        &:focus {
            text-decoration: none;
        }
        @include breakpoint(medium) {
            text-decoration: none;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
}
