@import "../../shared-components-styles/rich-text-common.scss";
.poor-text {
    @include font-size(20px, 28px);

    @include breakpoint(medium) {
        @include font-size(32px, 42px);
    }

    *:first-child {
        margin-top: 0;
    }
    p {
        margin: 0;
    }

    ul{
        @include bullet-list;
    }

    &_in-footer-contact-info {
        font-weight: normal;
        color: $dark-blue;

        @include font-size(14px, 24px);

        @include breakpoint(medium) {
            @include font-size(20px, 28px);
            max-width: 275px;
        }

        p {
            margin: 0;
        }
    }

    &_in-side-panel{
        color: $black;
        @include font-size(20px, 28px);

        @include breakpoint(medium) {
            @include font-size(22px, 36px);
        }
    }

    &_in-not-found-page { 
        color: $black;
        @include font-size(18px, 28px);

        @include breakpoint(medium) {
            @include font-size(22px, 36px);
        }
    }

    &_in-weekly-menu{
        @include font-size(20px, 26px);
        word-break: break-word;
    }

    &_in-technique-steps-carousel{
        color: $black;
        @include font-size(16px, 26px);
        @include breakpoint(large) {
            @include font-size(22px, 36px);
        }
    }

    &_in-teaser{
        color: $black;
        @include font-size(16px, 20px);
        @include breakpoint(small) {
            font-size: 1.6vw;
            line-height: 1.4;
        }
        @include breakpoint(medium) {
            font-size: 1.3vw;
            line-height: 1.6;
        }
        @include breakpoint($font-size-scaling-breakpoint) {
            @include font-size(22px, 36px);
        }
    }

    &_in-weekly-side-panel{
        @include font-size(16px, 20px);
        @include breakpoint(medium) {
            @include font-size(20px, 26px);
        }
    }

    &_in-tip-block{
        @include font-size(16px, 26px);
    
        @include breakpoint(medium) {
            @include font-size(22px, 36px);
        }
        p:not(:last-child) {
            margin-bottom: 20px;
        }
    
        ul li:before{
          background-color: currentColor;
        }
    }
}
