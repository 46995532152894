$suggestion-active-bg: rgba($light-gray, 0.5);

.search-form {
    $self: &;
    height: 60px;
    display: flex;
    width: 100%;

    @include breakpoint(medium) {
        height: 80px;
    }

    &_in-header {
        height: 50px;
        @include breakpoint(medium) {
            width: 345px;
        }
    }
    
    .react-autosuggest__container{
        flex: 1;
    }

    .react-autosuggest__input {
        width: 100%;
        border: $border-width solid $black;
        font-family: $font-family;
        padding: 10px 22px;
        height: 100%;
        @include reset-search-input;
        @include font-size(20px, 20px);
        @include placeholder {
            color: $gray;
        }

        @include breakpoint(medium) {
            padding: 10px 40px;
            @include font-size(28px, 28px);
        }
    }

    &_in-header .react-autosuggest__input {
        border: none;
        border-bottom: $border-width solid transparent;
        padding: 12px 0;
        font-size: 20px;
        width: 100%;

        @include breakpoint(medium) {
            width: 100%;
            font-size: 26px;
            margin: 2px 0 0;
            padding: 12px 0;
            border-width: 2.5px;
        }
    }

    &_in-header-active .react-autosuggest__input {
        border-bottom-color: $black;
    }

    .react-autosuggest__suggestions-container{
        background: white;
        width: 100%;
        overflow: hidden;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 0 5px 5px 0;
    }

    &_in-header .react-autosuggest__container{
        margin: 0 15px 0 45px;
        
        @include breakpoint(medium) {
            margin-left: 0;
        }
    }

    .react-autosuggest__suggestion{
        padding: 10px 5px 10px 24px;
        cursor: pointer;
        @include font-size(20px, 20px);
        
        &:hover,
        &:focus{
            background: $suggestion-active-bg;
        }

        @include breakpoint(medium) {
            @include font-size(28px, 28px);
            padding-left: 42px;
        }
    }

    &_in-header .react-autosuggest__suggestion{
        padding: 7px 5px 7px 10px;
        @include font-size(16px, 16px);

        @include breakpoint(medium) {
            @include font-size(20px, 20px);
        }
    }

    .react-autosuggest__suggestions-list{
        list-style: none;
        color: black;
        word-break: break-word;
    }

    .react-autosuggest__suggestion--highlighted{
        background: $suggestion-active-bg;
    }

    &__button {
        display: inline-flex;
        align-items: center;
        font-family: $font-family;
        @include font-size(20px, 20px);
        padding: 15px;
        border-width: $border-width $border-width $border-width 0;
        border-style: solid;
        border-color: $black;
        background: none;
        cursor: pointer;

        #{$self}_in-header & {
            display: none;
        }

        #{$self}_in-header-active & {
            @include breakpoint(medium) {
                display: inline-flex;
            }
        }

        @include breakpoint(medium) {
            @include font-size(28px, 28px);
            padding: 20px 40px;
        }

        &:hover,
        &:focus {
            background: $black;
        }

        &-text {
            &_hidden {
                @include visually-hidden;
            }
        }

        #{$self}_in-header & {
            border: none;
            padding: 5px;
            background: none;
            font-size: 26px;
            color: $medium-gray;
        }
    }

    &__icon {
        max-width: 30px;
        max-height: 30px;

        #{$self}__button:hover &,
        #{$self}__button:focus & {
            color: #fff;
        }
    }
}