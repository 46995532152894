.dictionary-block {
    padding: 25px 20px 35px;
    color: $black;
    margin-bottom: 40px;

    @include breakpoint(medium){
        margin-bottom: 60px;
        padding: 60px;
    }

    @include breakpoint(large){
        padding: 100px;
    }

    &__header {
        margin-bottom: 15px;

        @include breakpoint(medium){
            margin-bottom: 25px;
            display: flex;
            justify-content: space-between;
        }
    }

    &__heading {
        margin: 0 0 12px;
        @extend .h4;
        
        @include breakpoint(medium){
            margin: 0;
        }
    }

    &__hint {
        @include font-size(12px, 16px);

        @include breakpoint(medium){
            padding: 25px 0 0 20px;
            @include font-size(14px, 16px);
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: $border-width solid $black;
        @include font-size(16px, 26px);

        @include breakpoint(medium){
            padding: 15px 0;
            @include font-size(22px, 30px);
        }

        &:first-child {
            border-top: $border-width solid $black;
        }
    }

    &__value {
        font-weight: 500;
        padding-left: 15px;
        text-align: right;
    }
}