.load-more-button {
    display: block;
    color: $black;
    font-family: $font-family;
    @include font-size(18px, 20px);
    background-color: $white;
    border: 2.5px solid $black;
    padding: 15px 20px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.5s ease-in-out;
    margin: 0 auto;
    cursor: pointer;
    margin-bottom: 45px;
    outline: none;

    @include breakpoint(medium) {
        @include font-size(26px, 30px);
        padding: 17px 23px;
    }

    &:hover,
    &:focus,
    .mouse-user &:focus:hover {
        color: $white;
        background-color: $black;
        text-decoration: none;
    }

    &:focus {
        .mouse-user &{
            background-color: $white;
            color: $black;
        }
    }
}
