.colored-tag {
    padding: 8px;
    @include font-size(16px, 16px);
    font-weight: $font-weight-bold;
    color: $black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include breakpoint(medium){
        padding: 12px;
        @include font-size(24px, 24px);
    }

    &_with-link{
        text-decoration: none;
        position: relative;

        @include hovered-border;
    }

    &_active{
        position: relative;
        &:before{
            opacity: 1;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border: $border-width solid $black;
            z-index: 2;
            transition: opacity 0.3s, border-width 0.2s;
        }
    }

    &__text,
    &__icon{
        align-self: center;
    }

    &__icon{
        margin-right: 10px;
    }
}