.qa-block {
    @include container;
	max-width: $max-width-narrow;
    margin-bottom: $block-margin-bottom-mobile;

    @include breakpoint(medium) {
		margin-bottom: $block-margin-bottom;
	}

    
    .accordion__button[aria-expanded=true] {
        text-decoration: underline;
    }

    &__container {
        position: relative;
        color: $black;

        @include breakpoint(medium) {
            max-width: $max-text-width;
        }
    }

    &__item {
        &-heading {
            @include font-size(18px, 30px);

            @include breakpoint(medium) {
                padding: 5px 0;
                @include font-size(22px, 36px);
            }
        }
    }
}