@import "../../shared-components-styles/underlined-heading.scss";
.ingredients {
    $self: &;
    color: $black;

    @include breakpoint(medium, "max") {
        padding-bottom: 30px;
        border-bottom: 2px solid $black;
    }

    @media print {
        padding-bottom: 0;
        border-bottom: none;    
    }

    &__heading {
        @extend .underlined-heading;
    }

    #{$self}__heading {
        margin-bottom: 38px;
    
        @include breakpoint(medium, "max") {
            display: none;
        }

        @media print {
            display: block;
            margin-bottom: 15px;
        }
    }

    &__servings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-family: "Radikal", sans-serif;
        margin: 0 0 40px;
        border: 2px solid $black;
        position: relative;
        height: 68px;
        
        @include breakpoint(medium) {
            @include font-size(24px, 36px);
            margin: 0 0 34px;
            height: 75px;
        }
        @include breakpoint(medium, "max") {
            margin-top: 40px;
        }

        @media print {
            background: none;
            border: none;
            width: auto;
            height: auto;
            justify-content: flex-start;
            padding: 20px 0;
            border-top: 2px solid $black;
            border-bottom: 2px solid $black;
            font-size: 20px;
            margin: 20px 0;
        }

        &-button {
            &_minus,
            &_plus {
                position: absolute;
                right: 0px;
                width: 47px;
                text-indent: 50px;
                overflow: hidden;
                height: 50%;
                border: none;
                background: none;
                border-left: 2px solid $black;
                cursor: pointer;

                .mouse-user & {
                    outline: none;
                }

                &:after {
                    content: " ";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 0;
                    height: 0;
                    margin: -2px 0 0 -5px;
                }

                &:hover,
                &:focus{
                    background-color: $coral;
                }
                .mouse-user &:focus{
                    background: none;
                    &:hover{
                        background-color: $coral; 
                    }
                }


                @media print {
                    display: none;
                }
            }

            &_plus {
                top:0;
                border-bottom: 1px solid $black;
                &:after {
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 5px solid $black;
                }
            }

            &_minus {
                bottom: 0;
                border-top: 1px solid $black;

                &:after {
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $black;
                }
            }
        }
        &-label {
            width: calc(100% - 105px);
            display: flex;
            padding: 0 10px;
            align-content: center;
            justify-content: center;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;
            align-self: center;
            text-align: center;
            word-break: break-word;

            @media print {
                color: #000;
                padding: 0;
                width: auto;

                &:after {
                    content: ':'
                }
            }
        }

        &-panel {
            margin: 0;
            width: 105px;
            padding: 2px 30px 2px 2px;
            border-left: 2px solid $black;
            background: #fff;
            height: 100%;

            @media print {
                border-left: none;
                width: auto;
                padding-right: 0;
            }
        }

        &-count {
            align-self: flex-start;
            flex: 1;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;
            width: 52px;
            height:100%;
            border: none;
            text-align: center;
            padding: 0;
            display: block;

            .mouse-user & {
                outline: none;
            }

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }

            @media print {
                color: #000;
            }
        }
    }

    &__section {
        margin: 0 0 30px;
        padding: 0;
        border: none;
        font-size: inherit;

        @include breakpoint(medium) {
            margin-bottom: 40px;
        }

        &-heading {
            display: block;
            width: 100%;
            @include font-size(30px, 36px);
            font-weight: 500;
            margin: 0 0 20px;
            font-family: $font-family-secondary;

            @include breakpoint(medium) {
                @include font-size(30px, 36px);
                margin-bottom: 16px;
            }

            @media print {
                @include font-size(22px, 28px);
            }
        }

        &-item {
            position: relative;
            margin: 0 0 18px;
            @include font-size(16px, 18px);

            @include breakpoint(medium) {
                @include font-size(20px, 24px);
                margin-bottom: 16px;
            }
            &:last-child{
                margin-bottom: 0;
            }

            @media print {
                margin-bottom: 10px;
            }

            &-name,
            &-unit,
            &-amount{
                margin-right: 5px; 
            }

            &-content{
                #{$self}__section-item-checkbox:checked + #{$self}__section-item-label &{
                    color: #00000099;
                    text-decoration: line-through;
                }
            }

            &-unit{
                margin-right: 15px;
            }

            &-label{
                padding-left: 47px;
                display: inline-flex;
                cursor: pointer;

                @include breakpoint(medium) {
                    padding-left: 44px;
                }

                @media print {
                    padding-left: 0;
                }
            }

            &-checkbox{
                @include visually-hidden;

                &-icon{
                    position: absolute;
                    top:0;
                    left: 0;
                    width: 18px;
                    height: 18px;
                    border: 2px solid $black;
                    background: $white;

                    @include breakpoint(medium) {
                        width: 24px;
                        height: 24px;
                    }
                    #{$self}__section-item-checkbox:checked + #{$self}__section-item-label &{
                        background: $coral;
                    }
              
                    #{$self}__section-item-label:hover &,
                    #{$self}__section-item-checkbox:focus + #{$self}__section-item-label &{
                        border-color: $coral;
                        box-shadow: 0px 3px 6px #0000004D;
                    }

                    #{$self}__section-item-checkbox:checked + #{$self}__section-item-label:hover &,
                    #{$self}__section-item-checkbox:checked:focus + #{$self}__section-item-label &{
                        border-color: $black;
                    }

                    @media print {
                        display: none;
                    }
                }
            }
        }
    }
}