.react-responsive-modal {
	&-root {
		position: fixed;
		z-index: 101;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		padding: 20px;

		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
		}
	}

	&-overlay {
		position: fixed;
		background-color: $white!important;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
		padding: 20px;
		text-align: center;
		transition: all 500ms ease-in-out;
	}

	&-modal {
		max-width: none;
		box-shadow: none;
		padding: 0;
		overflow: visible;
	}

	&-container {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin: auto;
		border: none!important;
		padding: 0!important;
		overflow: visible!important;
		border-radius: 0!important;
		width: 1280px;
		max-width: 100%;
		z-index: 112;
		height: auto;
	}
}

.modal {
	&__wrapper {
		position: relative;
		margin: 60px 0;
		width: 100%;

		@include breakpoint(medium) {
			margin: 60px 0;
		}
	
		@include breakpoint(extra-large) {
			margin: 120px 0;
		}
	}

	&__title {
		@include visually-hidden;
	}

	&__close-btn {
		background: none;
		border: none;
		cursor: pointer;
		height: 32px;
		width: 32px;
		padding: 0;
		top: -60px;
		@include horizontal-align(absolute);
		@include flex-centered;

		@include breakpoint(medium) {
			top: -60px;
		}

		@include breakpoint(extra-large) {
			top: -90px;
		}

		&:before, &:after {
			position: absolute;
			left: 15px;
			content: ' ';
			height: 32px;
			width: 4px;
			background-color: $gray;
			border-radius: 3px;
			transition: background-color 200ms;
		}

		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}

		&:hover {
			&:before, &:after {
				background-color: $black;
			}
		}
	}	

	&__body-modal-opened {
		overflow: hidden!important;
	}
}
