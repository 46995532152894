.view-select {
    display: flex;
    flex-wrap: nowrap;
    color: $black;

    &__label {
        align-self: center;
        display: block;
        opacity: 0.5;
        @include font-size(16px, 16px);
        font-weight: $font-weight-normal;
    }

    &__button {
        @include clear-styles;
        cursor: pointer;
        margin: 0 0 0 10px;
        align-self: center;
        opacity: 0.5;
        transition: opacity 0.3s ease-in-out;

        &:hover,
        &:focus,
        &_active {
            opacity: 1;
        }

        &-icon{
            display: block;
        }
    }
}
