.search-section {
    border-top: $border-width solid $black;
    padding: 39px 0 15px;
    position: relative;

    @include breakpoint(medium) {
        padding: 56px 0 69px;
    }

    &__heading {
        margin: 0;
        @include font-size(28px, 33px);

        @include breakpoint(medium) {
            @include font-size(50px, 60px); 
        }
    }

    &__container {
        @include container;
        overflow: hidden;
    }

    &__items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        list-style: none;
        align-content: stretch;
        width: 100%;
        padding: 45px 0 15px;

        @include breakpoint(medium) {
            width: calc(100% + 40px);
            margin-right: -40px;
            padding: 50px 0 27px;
        }
    }

    &__responsive-grid {
        width: 100%;
    }

    &__item {
        margin-bottom: 30px;

        @include breakpoint(medium) {
            width: calc((100% / 3) - 40px);
            margin-right: 40px;
            margin-bottom: 60px;
        }

        @include breakpoint(large) {
            width: calc(25% - 40px);
        }
    }

    &__teaser{
        @include breakpoint(medium) {
            min-height: 100%;
        }
    }

}
