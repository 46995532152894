$top-banner-panel-height: 90px;
$top-banner-panel-height-medium: 130px;

.top-banner-block {
	$self: &;
	display: flex;
	flex-direction: column;
	@include container;

	@include breakpoint(small) {
		height: calc(100vh - #{$header-height});
	}

	@include breakpoint(ipad-pro) {
		height: calc(100vh - #{$header-height-medium});
	}
	
	@media (max-height: 800px) and (min-width: 768px) {
		height: calc(100vh - #{$header-height});
	}
	
	@media (max-height: 700px) and (min-width: $ipad-pro-breakpoint) {
		height: auto;
	}

	.page__root.on-page-editing &{
		height: auto;
	}

	&__heading-tags {
		align-self: center;
	}

	&__container {
		position: relative;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		@include breakpoint(small) {
			height: calc(100vh - #{$header-height} - #{$top-banner-panel-height});
		}

		@include breakpoint(medium) {
			height: calc(100vh - #{$header-height-medium} - #{$top-banner-panel-height-medium});
		}

		@media (max-height: 800px) and (min-width: 768px) {
			height: calc(100vh - #{$header-height} - #{$top-banner-panel-height});
		}

		@include breakpoint(ipad-pro) {
			flex-direction: row;
		}

		@media (max-height: 700px) and (min-width: $ipad-pro-breakpoint) {
			height: auto;
		}

		.page__root.on-page-editing &{
			height: auto;
		}
	}

	&__media {
		position: relative;
		overflow: hidden;
		@include fixed-proportion-image(338, 273);

		@include breakpoint(small) {
			height: 50%;
			padding-bottom: 0;
		}

		@include breakpoint(ipad-pro) {
			width: 50%;
			height: auto;
			min-height: 0;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 50px 30px;
		width: 100%;

		@include breakpoint(small) {
			min-height: 50%;
		}

		@include breakpoint(ipad-pro) {
			width: 50%;
			padding: 120px 100px 120px 120px;
		}

		@media (max-height: 1200px) and (min-width: 768px) {
			padding: 60px;
		}
	}

	&__heading {
		color: $dark-blue;
		word-wrap: break-word;
		margin: 0;
		text-align: center;

		@include breakpoint(small) {
			font-size: 80px;
			line-height: 80px;
		}

		@include breakpoint(large) {
			font-size: 100px;
			line-height: 100px;
		}

		@include breakpoint(extra-large) {
			font-size: 140px;
			line-height: 140px;
		}

		&-line {
			display: block;
		}
	}

	&__btn {
		&-holder {
			margin-top: 40px;
			text-align: center;
		}
	}

	&__link {
		text-decoration: none;
		color: inherit;

		#{$self}__image {
			transition: transform 300ms;
		}
		
		&:hover {
			background: $white;
			#{$self}__heading-line {
				text-decoration: underline;
				text-decoration-thickness: 4px;

				@include breakpoint(small) {
					text-decoration-thickness: 6px;
				}
			}
			#{$self}__image {
				@include image-hover-zoom;
			}
		}
	}

	&__bottom-panel {
		display: flex;
		justify-content: center;
		align-items: center;
		height: $top-banner-panel-height;

		@include breakpoint(medium) {
			height: $top-banner-panel-height-medium;
		}

		@media (max-height: 800px) and (min-width: 768px) {
			height: $top-banner-panel-height;
		}
	}

	&__scroll-btn {
		transform: rotate(180deg);
	}
}