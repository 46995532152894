.related-items{
	border-top: $border-width solid $black;
    padding-top: 119px;
    overflow: hidden;
    @include breakpoint(medium, "max"){
        margin-bottom: $block-margin-bottom-mobile;
        padding-bottom: 10px;
        padding-top: 39px;
    }
	@media print {
		display: none;
	}
}
.related-items-container{
	@include container;

	@include breakpoint(medium, "max"){
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
.related-items-see-all-button{
	align-self: center;
	margin: 50px 0 0;
	padding: 13px 23px;
}