@mixin video-container ($proportion: 56.25%) {
	height: 0;
	position: relative;
	padding-bottom: $proportion;
}

.video {
	$self: &;
	position: relative;
	background-color: $light-gray;
	overflow: hidden;
	@include video-container(42.25%);

	&_fluid{
		height: auto;
		padding-bottom: 0;
		@include full-size();
	}

	&__container {
		@include video-container;

		& > div, & > div > div { // Wrapper blocks generated by React-player module https://www.npmjs.com/package/react-player
			width: auto!important;
			height: auto!important;
			@include full-size;
			@include object-fit(cover, center);
		}
	}

	&__modal-holder {
		margin: auto;
		max-width: 135vh;
	}

	&__image {
		z-index: 1;
	}

	&__play-btn {
		display: inline-block;
		@include center-align(absolute);
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background-color: $white;
		border: none;
		cursor: pointer;
		transition: background-color 200ms;

		@include breakpoint(medium) {
			width: 122px;
			height: 122px;
		}

		&:after {
			content: "";
			@include center-align(absolute);
			width: 0px;
			height: 0px;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 16px solid $dark-blue;
			transition: border-left-color 200ms;
			margin-left: 3px;

			@include breakpoint(medium) {
				border-top-width: 15px;
				border-bottom-width: 15px;
				border-left-width: 28px;
				margin-left: 5px;
			}
		}

		&-holder {
			z-index: 3;
			@include full-size;
			cursor: pointer;

			&:hover {
				.video__play-btn {
					background-color: $dark-blue;
					&:after {
						border-left-color: $white;
					}
				}
			}
		}
	}

	iframe { // generated by React-player module https://www.npmjs.com/package/react-player
		@include full-size;
	}
}