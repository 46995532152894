$grid-gap: 6%;
$grid-small-gap: 2%;
$small-teaser-width: 33%;
$small-teaser-width-mobile: 40%;

.teasers-list {
    @include container;
    margin-bottom: $block-margin-bottom-mobile;

    @include breakpoint(medium) {
        margin-bottom: $block-margin-bottom;
    }

    &__row {
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
            margin-bottom: 40px;
        }

        @include breakpoint(small) {
            flex-direction: row;
            &:not(:last-child) {
                margin-bottom: $grid-small-gap;
            }
        }

        @include breakpoint(medium) {
            &:not(:last-child) {
                margin-bottom: $grid-gap;
            }
        }
    }

    &__item {        
        &:not(:last-child) {
            margin-bottom: 40px;
        }

        @include breakpoint(small) {
            &, &:not(:last-child){
                margin-bottom: 0;
            }

            &:not(:last-child) {
                margin-right: $grid-small-gap;
            }
        }

        @include breakpoint(medium) {
            &:not(:last-child) {
                margin-right: $grid-gap;
            }
        }

        &_1-3 {
            @include breakpoint(small) {
                width: $small-teaser-width-mobile;
            }
            @include breakpoint(medium) {
                width: $small-teaser-width;
            }
        }

        &_2-3 {
            @include breakpoint(small) {
                width: calc(100% - #{$grid-small-gap} - #{$small-teaser-width-mobile});
            }
            @include breakpoint(medium) {
                width: calc(100% - #{$grid-gap} - #{$small-teaser-width});

            }
        }

        &_full-width {
            width: 100%;
        }
    }
}