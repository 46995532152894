.recipe-filter {
    $self: &;

    &__heading-text{
        @include font-size(20px, 20px);
        display: block;
        font-family: $font-family-secondary;

        @include breakpoint(medium){
            @include font-size(30px, 36px);
            padding: 28px 0 22px;
            display: block;
        }
    }

    &__toggle-button{
        @include flex-button;
        padding: 17px 10px;
        width: 100%;

        &-icon{
            margin-right: 20px;
            transition: transform 0.5s ease-in-out;

            &_rotated{
                transform: rotate(180deg);
            }
        }        
    }
    &__panel{
        @include breakpoint(medium, "max"){
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s ease-in-out;
            padding-left: 10px;

            &_opened-in-mobile {
                max-height: 1000px;
                overflow-y: auto;
                padding: 0 0 22px 10px;
            }
        }

        @include breakpoint(medium){
            max-height: 250px;
            overflow-y: auto;
            margin-bottom: 30px;
            padding: 0;
            overflow-y: auto;
        }
    }

    &__item {
        &_grayed-out {
            opacity: 0.5;
        }
    }
}