$teaser-desktop-width: calc((100% - 120px) / 4);
$teaser-tablet-width: calc((100% - 80px) / 3);
.weekly-menu-block {
    $self: &;
    background: $light-beige;
    padding-top: 60px;
    padding-bottom: 65px;
    margin: 0 0 $block-margin-bottom-mobile;

    @include breakpoint(medium) {
        padding-top: 85px;
        padding-bottom: 125px;
        margin-bottom: $block-margin-bottom;
    }

    &__container {
        @include container;

        @include breakpoint(medium, "max") {
            display: flex;
            flex-direction: column;
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
        margin: 0 0 45px;

        @include breakpoint(medium) {
            margin-bottom: 60px;
            justify-content: flex-start;
        }
    }

    &__heading-tags {
        order: 2;
        margin: 0;
        align-self: flex-start;
        text-align: center;

        @include breakpoint(ipad-pro) {
            max-width: calc(#{$teaser-desktop-width}* 2);
            text-align: left;
            align-self: flex-start;
        }
    }

    &__heading {
        margin: 0;
        @include font-size(60px, 60px);

        @include breakpoint(medium) {
            @include font-size(100px, 100px);
            word-break: break-word;
        }
    }

    &__intro {
        order: 1;
        align-self: flex-start;
        flex: 0 0 0 $teaser-desktop-width;
        width: $teaser-desktop-width;
        margin-right: 40px;
        display: none;
        
        @include breakpoint(medium) {
            display: block;
            align-self: center;
        }

        &-text{
            max-width: 190px;
        }
    }

    &__items {
        @include breakpoint(medium) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        @include breakpoint(large) {
            flex-wrap: nowrap;
        }
    }

    &__item {
        @include breakpoint(medium) {
            width: $teaser-tablet-width;
        }

        @include breakpoint(large) {
            width: $teaser-desktop-width;
        }

        &#{$self}__side-panel {
            @include breakpoint(medium-max, "max") {
                width: 100%;
                flex: 0 0 100%;
            }
        }
    }

    &__side-panel {
        background: $light-yellow;
        padding: 30px 30px 90px;
        display: flex;
        flex-direction: column;
        margin: 70px 0 0;
        box-shadow: $teaser-shadow;

        @include breakpoint(large) {
            margin: 0;
            padding: 40px 20px 20px;
        }

        @include breakpoint(1600px, "min") {
            padding: 40px 40px 90px;
        }

        &-heading {
            margin: 0;
            @include font-size(32px, 38px);
            @include breakpoint(medium) {
                @include font-size(40px, 50px);
            }
        }

        &-text {
            margin: 20px 0 0;
            @include breakpoint(medium) {
                margin: 35px 0 0;
            }
        }
        &-logo {
            align-self: center;
            margin: -50px 0 10px;
            max-width: 100px;
            max-height: 100px;

            @include breakpoint(large) {
                align-self: flex-end;
                margin: -50px -37px 0px 0;
            }

            @include breakpoint(1600px, "min") {
                max-width: 146px;
                max-height: 146px;
            }
        }
    }

    &__see-all-button {
        margin: 60px 0 0;
        align-self: center;
        padding: 13px 20px;

        #{$self}__header & {
            order: 3;
            align-self: center;
            white-space: nowrap;
            margin: 0 0 0 auto;
            padding: 17px 25px;
        }
    }
}