.tip-block {
    @include container;
	max-width: $max-width-narrow;
    margin-bottom: $block-margin-bottom-mobile;

	@include breakpoint(medium) {
		margin-bottom: $block-margin-bottom;  
	}

    &_in-accordion {
        padding-top: 20px;
        margin-bottom: $block-margin-bottom-in-accordion;
        padding-left: 0;
    	padding-right: 0;
    }

    &__container {
        position: relative;
        background-color: rgba($light-orange, 0.5);
        padding: 30px;
        color: $black;

        @include breakpoint(medium) {
            max-width: $max-text-width;
            padding: 45px 40px;
        }
    }

    &__heading {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(7deg);
        background-color: $coral;
        font-weight: 600;
        padding: 10px;
        @include font-size(16px, 16px);

        @include breakpoint(medium) {
            @include font-size(24px, 24px);
		}
    }
}