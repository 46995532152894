.expandable-panel {
  $self: &;
  max-height: none;
  border: none;
  margin: 0;
  background: none;
  padding: 0;
  width: 100%;
  margin: 0 0 40px;

  &__toggle {
    border: none;
    position: relative;
    min-height: 60px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    background: none;
    padding: 10px 10px 10px 30px;
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
    font-family: $font-family-secondary;
    font-weight: normal;
    color: $black;
    margin: 0;
    @include font-size(32px, 38px);

    &-icon {
      position: absolute;
      left: 0px;
      top: 50%;
      margin: -7px 0 0;
      width: 14px;
      height: 14px;
      transition: transform 0.3s ease-in-out;
    }

    @include breakpoint(medium) {
      display: none;
    }

    @media print {
      display: none;
    }

    #{$self}_opened-in-mobile &{
      border-bottom-color: rgba($black, 0.2);
    }
  }

  &_opened-in-mobile &__toggle-icon {
    transform: rotate(45deg);
  }

  &__content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    @include cf;

    @include breakpoint(medium) {
      max-height: none;
      border: none;
      margin: 0;
      background: none;
      padding: 0;
      width: 100%;
      padding: 0;
    }

    @media print {
      max-height: none;
      background: none;
      padding: 0 40px 0 0;
    }
  }


  &_opened-in-mobile &__content {
    @include breakpoint(small-max, $min-max: "max") {
      max-height: 1600px;
    }
  }
}
