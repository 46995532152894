.accordion-block {
    @include container;
	max-width: $max-width-narrow;
    margin-bottom: $block-margin-bottom-mobile;

    @include breakpoint(medium) {
		margin-bottom: $block-margin-bottom;
	}

    &__container {
        position: relative;
        color: $black;

        @include breakpoint(medium) {
            max-width: $max-text-width;
        }
    }

    &__heading {
        @include font-size(32px, 38px);
        margin: 0 0 20px;

        @include breakpoint(medium) {
            @include font-size(50px, 60px);
            margin: 0 0 35px;
        }
    }

    &__item {
        &-heading {
            font-family: $font-family-secondary;
            @include font-size(20px, 24px);
            position: relative;
            padding-left: 25px;

            @include breakpoint(medium) {
                padding-left: 70px;
                @include font-size(40px, 50px);
            }
        }

        &-number {
            position: absolute;
            top: 0;
            left: 0;

            @include breakpoint(medium) {
                left: 20px;
            }
        }

        &-content {
            @include cf;
        
            @include breakpoint(medium) {
                padding-top: 15px;
                margin-bottom: -10px;
            }
        }
    }
}