$header-line-height-mobile: 70px;
$header-line-height-desktop: 150px;

.page-heading {
    $self: &;
    border-bottom: $border-width solid $black;
    position: relative;
    margin: 20px 0 30px;
    background: linear-gradient($black $border-width, transparent 0);
    background-size: 100% $header-line-height-mobile;

    @media print {
        margin: 30px 0 80px;
        background-size: 100% 100px;
    }

    @include breakpoint(medium) {
        background-size: 100% $header-line-height-desktop;
        margin: 60px 0;
    }

    &:first-child{
        margin-top: 0;
    }

    &_small{
        background: none;
        border: none;
        margin: 0 0 18px;
        padding-top: 23px;
        letter-spacing: 0.2px;

        @include breakpoint(medium) {
            padding-top: 15px;
            margin-bottom: 29px;
            letter-spacing: 0.18px;
        }
    }

    &:first-child{
        padding-top: 0;
    }

    &__text{
        @include container;
        max-width: $max-width-narrow;
        display: block;
        font-family: $font-family-secondary;
        font-weight: normal;
        color: $black;
        @include font-size(50px,$header-line-height-mobile);

        @include breakpoint(medium) {
            @include font-size(126px, $header-line-height-desktop);
        }

        #{$self}_small &{
            @include font-size(40px, 45px);
            max-width: $max-width;

            @include breakpoint(medium) {
                @include font-size(70px, 80px);
            }
        }

        @media print {
            font-size: 80px;
            line-height: 100px; 
        }
    }
}