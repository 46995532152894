.recipe-teaser {
    $self: &;
    text-decoration: none;
    color: $black;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 38px;
    transition: background-color 0.3s, background-color 0.5s;
    @include hovered-border;
    box-shadow: $teaser-shadow;

    @include breakpoint(ipad-pro) {
        padding-bottom: 80px;
    }

    &:hover,
    &:focus{
		background-color: $white !important;
    }

    &__heading{
        @include font-size(20px, 30px);
        padding-left: 10px;
        padding-right: 10px;
        @include stripedHeading(30px, 64px, 2px, 2px, $black);
        margin: 0 0 20px;

        @include breakpoint(extra-small-max, "max") {
            @include font-size(32px, 42px);
            @include stripedHeading(42px, 42px, 2px, 2px, $black);
        }

        @include carousel-ipad-pro-view {
            @include font-size(32px, 42px);
            @include stripedHeading(42px, 42px, 2px, 2px, $black);
        }

        @include breakpoint(ipad-pro) {
            @include font-size(50px, 64px);
            
            padding-left: 20px;
            padding-right: 20px;
            margin: 0;
        }  

        #{$self}.carousel__teaser &{
            @include breakpoint(extra-small-max, "max") {
                @include font-size(32px, 42px);
                @include stripedHeading(42px, 42px, 2px, 2px, $black);
            }
        }
    }

    &__image{
        transition: transform 300ms;

		&-container{
			overflow: hidden;
            
            &_no-image{
                @extend .image_size_recipe-teaser;                
            }
		}

        #{$self}:hover &,
        #{$self}:focus &{
            @include image-hover-zoom;
        }
    }

    &__details {
        position: absolute;
        bottom: 20px;
        right: 10px;
        @include breakpoint(ipad-pro){
            right: 20px;
            font-size: convert-to-rem(18px);
        }
    }

    &__icon {
        position: absolute;
        bottom: 20px;
        left: 10px;
        height: 14px;

        @include breakpoint(ipad-pro){
            left: 20px;
            opacity: 0.3;
            height: 19px;
        }

        #{$self}:hover &,
        #{$self}:focus &{
            opacity: 1;
        }
    }
}
