.video-block {
	@include container;
	max-width: $max-width-narrow;
	margin-bottom: $block-margin-bottom-mobile;

	@include breakpoint(medium) {
		margin-bottom: $block-margin-bottom;
	}

	&_in-accordion {
        margin-bottom: $block-margin-bottom-in-accordion;
		padding-left: 0;
    	padding-right: 0;
    }

	&__container {
		@include breakpoint(medium) {
			max-width: $max-text-width;
		}
	}
}