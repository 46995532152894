.recipes-overview-page {
    $self: &;
    position: relative;

    @include breakpoint(medium, "max") {
        padding-bottom: 10px;
    }

    &__heading {
        @include breakpoint(medium, "max") {
            padding-top: 0;
            margin-top: -15px;
            margin-bottom: 31px;
        }
    }

    &__control-panel {
        @include container;
        position: relative;
        display: flex;
        flex-direction: column;

        @include breakpoint(small) {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
            padding-top: 25px;
        }

        &-group {
            &:nth-child(1) {
                margin-bottom: 30px;

                @include breakpoint(small) {
                    margin: 0 10px 0 0;
                }
            }

            &:nth-child(2) {
                position: relative;
                display: flex;
                align-self: flex-end;
                margin-left: auto;
                padding: 0 0 15px;
                margin-bottom: 0px;

                @include breakpoint(small, "max") {
                    width: 100%;
                    justify-content: space-between;
                }

                @include breakpoint(medium) {
                    padding-bottom: 20px;
                }
            }
        }
    }

    &__teasers {
        @include container;
        overflow: hidden;
    }
    &__teasers-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        list-style: none;
        align-content: stretch;
        width: 100%;
        padding: 27px 0;

        @include breakpoint(small) {
            width: calc(100% + 40px);
            margin-right: -40px;
            padding: 50px 0 40px;
        }
        @include breakpoint(large) {
            padding-top: 94px;
        }

    }

    &__teaser-item {
        margin-bottom: 40px;
        width: 100%;

        #{$self}__items_two-columns & {
            @include breakpoint(small, "max") {
                margin-bottom: 30px;
            }
        }

        @include breakpoint(small) {
            margin-right: 40px;
            margin-bottom: 60px;
            width: calc((100% / 2) - 40px);
        }

        @include breakpoint(medium) {
            width: calc((100% / 3) - 40px);
        }

        @include breakpoint(large) {
            width: calc(25% - 40px);
        }

        
    }
    &__teaser {
        min-height: 100%;
    }

    &__pager {
        display: flex;
        justify-content: center;
        margin: 0 auto 70px;

        @include breakpoint(medium) {
            margin-bottom: 100px;
        }
    }

    &__no-results{
        &-heading,
        &-text{
            color: $black;
            @include container;
        }
        &-heading{
            @include font-size(32px, 38px);
            margin: 44px auto 16px;

            @include breakpoint(medium) {
                @include font-size(50px, 60px);
                margin: 115px auto 40px;
            }
        }
        &-text{
            @include font-size(16px, 26px);
            margin:0 auto 145px;

            @include breakpoint(medium) {
                @include font-size(22px, 36px);
            }
        }
    }
}
