@import "../../shared-components-styles/article-common-styles.scss";

.specimen-page{
    $self: &;

    &__categories{
        @include article-page-categories;
    }
    
    &__intro,
    &__body{
        @include article-page-content;

        &-text{
            @include article-page-text;
        }
    }

    &__tabs-content {
        margin: 40px 0;

        @include breakpoint(medium){
            margin: 100px 0 40px;
        }
    }

    &__tab-content {
        @extend .container;
    }

    &__columns {
        @include breakpoint(medium){
            display: flex; 
        }
    }

    &__main-column {
        @include breakpoint(medium){
            width: 60%;
            margin-right: 5%;
        }
    }

    &__aside-column {
        #{$self}__main-column + & {
            margin-top: 40px;
            @include breakpoint(medium){
                margin-top: 0;
            }
        }

        @include breakpoint(medium){
            width: 35%;
        }
    }

    &__share-panel {
        @include container;
        max-width: $max-width-narrow;
        margin-bottom: $block-margin-bottom;
        
        @include breakpoint(medium) {
            margin-bottom: 160px;
        }
    }
}