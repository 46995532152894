@mixin list-base {
	margin: 20px 0;

	li {
		padding: 0 0 0 30px;
		margin-bottom: 20px;
		position: relative;
		list-style: none;
		@media print {
			list-style: disc;
		}

		@include breakpoint(medium) {
			padding-left: 51px;
		}

		@media print {
			padding: 0 0 0 10px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			@media print {
				content: none;
			}
		}
	}
}

@mixin bullet-list {
	@include list-base;

	li:before {
		width: 10px;
		height: 10px;
		background: $coral;
		border-radius: 50%;
		overflow: hidden;
		top: 7px;
		left: 0px;
		@include breakpoint(medium) {
			width: 16px;
			height: 16px;
			top: 10px;
		}
	}
}

@mixin ordered-list {
	@include list-base;

	li{
		counter-increment: my-ol-counter;

		&:before {
			content: counter(my-ol-counter);
			font-weight: bold;
			line-height: inherit;
			color: $coral;
		}
	}
}