.rate-recipe {
    display: flex;
    flex-direction: column;
    margin: 0;
    position: relative;
    padding-bottom: 60px;
    $self: &;
    z-index: 1;

    @include breakpoint(medium) {
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        padding-bottom: 78px;
    }

    &__heading {
        padding: 0 0 16px;
        @include font-size(16px, 24px);
        font-weight: $font-weight-normal;
        color: $black;

        @include breakpoint(medium) {
            @include font-size(22px, 28px);
            padding: 0 24px 8px 0;
        }
    }

    &__items {
        display: flex;
        flex-direction: row;
        list-style: none;
    }

    &__item-container {
        margin: 0 17px 0 0;

        &:last-child {
            margin-right: 0;
        }
    }

    &__item {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;

        &:after {
            content: "";
            display: block;
            width: 40px;
            height: 40px;
            background: $coral;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            transition: transform 0.25s ease-in-out;
            transform: scale(1);
        }

        #{$self}__item_active:after,
        &:hover:after,
        &:focus-visible:after {
            transform: scale(1.4);
        }

        &:active:after {
            transform: scale(1.5);
        }

        &:disabled {
            pointer-events: none;
        }

        &-icon {
            max-width: 24px;
            max-height: 24px;
            z-index: 2;
            display: block;
            @include center-align(absolute);
            will-change: transform;
            transition: opacity 0.25s ease-in-out;
            opacity: 1;
            transform-origin: center;
            transition: transform 0.25s ease-in-out;

            #{$self}__item:hover &,
            #{$self}__item:focus-visible & {
                transform: translate(-50%, -50%) scale(1.2);
            }

            &_empty {
                #{$self}__item_active &,
                #{$self}__item:hover &,
                #{$self}__item:focus-visible & {
                    opacity: 0;
                }
            }

            &_full {
                opacity: 0;
                z-index: 3;

                #{$self}__item_active &,
                #{$self}__item:hover &,
                #{$self}__item:focus-visible & {
                    opacity: 1;
                }
            }
        }
    }

    &__notifications {
        width: 100%;
        flex: 0 0 100%;
        margin-top: 3px;
        min-height: 28px;
        position: absolute;
        top: 100%;
        left: 0;
        color: #000000e5;
        @include font-size(14px, 18px);

        @include breakpoint(medium) {
            margin-top: 10px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__hint {
        opacity: 0;
        animation: fadeIn 0.25s ease-in-out forwards;
    }

    &__error-text {
        color: red;
    }
}