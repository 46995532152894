@import "../../shared-components-styles/underlined-heading.scss";

.share-and-rate-recipe-panel {
    $self: &;
    margin: 64px 0;
    @include cf;
    
    @include breakpoint(medium) {
        margin: 64px 0 0;
    }

    &__heading {
        display: block;
        margin-bottom: 16px;
        font-family: $font-family;
        @include font-size(20px, 30px);

        @include breakpoint(medium) {
            align-self: center;
            margin-bottom: $spacing-large;
            padding-bottom: $spacing-medium;
            border-bottom: 2px solid $black;

            #{$self} & {
                @include font-size(32px, 42px);
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;

        @include breakpoint(medium) {
            flex-direction: row;
            gap: 0 30px;
        }
    }

    &__rate-recipe {
        margin-top: 10px;

        @include breakpoint(medium) {
            margin-top: 15px;
        }
    }
}