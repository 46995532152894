.toggle-button {
    @include flex-button;
    color: $dark-blue;
    background-color: $white;
    padding: 14px 13px;
    @include font-size(16px, 16px);
    cursor: pointer;
    border: $border-width solid $dark-blue;
    transition: all 0.3s ease-in-out;
    align-self: flex-start;
    white-space: nowrap;

    @include breakpoint(small) {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    @include breakpoint(medium) {
        padding: 14px 12px;
        @include font-size(24px, 24px);
        padding: 11px 10px;
    }

    &:hover,
    &:focus,
    &_active {
        color: $white;
        background-color: $dark-blue;
    }

    &__icon {
        align-self: center;
        margin-left: 10px;
        transition: transform 0.5s ease-in-out;

        &_rotated {
            transform: rotate(180deg);
        }
    }

    &__container {
        $self: &;
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
    }

    &__hint {
        display: none;
        margin: 0 30px 0 0;
        align-self: center;
        color: $black;
        opacity: 0.5;
        @include font-size(16px, 18px);

        @include breakpoint(large, "min") {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
        }

        &-icon {
            display: block;
            align-self: center;
            margin-left: 10px;
        }
    }
}
