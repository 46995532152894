.rich-text-block {
    clear: both;
    @include container;
    max-width: $max-width-narrow;
    margin-bottom: 40px;

	@include breakpoint(medium) {
		margin-bottom: $block-margin-bottom;
	}

    &_in-accordion {
        margin-bottom: $block-margin-bottom-in-accordion;
        padding-left: 0;
    	padding-right: 0;
    }

    &__content{
        @include breakpoint(medium){
            max-width: $max-text-width;
        }
    }
}