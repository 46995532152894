.checkbox {
  $self: &;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 9px;
  justify-content: flex-start;
  cursor: pointer;

  &_disabled {
    cursor: default;
    opacity: 0.5;
  }

  &__input {
    @include visually-hidden;

    &:hover ~ #{$self}__label,
    &:focus ~ #{$self}__label {
      text-decoration: underline;
    }
    &:hover ~ #{$self}__check-mark,
    &:focus ~ #{$self}__check-mark {
      border-color: $coral;
      box-shadow: 0px 3px 6px #0000004D;
    }

    &:checked:hover ~ #{$self}__check-mark,
    &:checked:focus ~ #{$self}__check-mark {
      border-color: $black;
    }
  }

  &__checkmark {
    box-sizing: border-box;
    flex: 0 0 15px;
    align-self: flex-start;
    align-items: center;
    width: 15px;
    height: 15px;
    border: 2px solid $black;
    margin-right: 13px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    &_checked {
      background: $coral;
    }
  }


  &__label {
    flex: auto;
    color: black;
    @include font-size(16px, 16px);
    align-self: flex-start;
  }

}
