.text-with-side-panel {
    @include container;
    max-width: $max-width-narrow;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @include breakpoint(medium){
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 55px;
    }

    &__main-content{
        padding-bottom: 30px;

        @include breakpoint(medium){
            flex: 0 0 5;
            max-width: $max-text-width;
            margin: 0 60px 100px 0;
        }
    }

    &__heading{
        margin: 0 0 17px;
        @include font-size(32px, 38px);

        @include breakpoint(medium){
            margin-bottom: 37px;
            @include font-size(50px, 60px);
        }
    }

    &__side-panel{
        padding: 89px 36px 45px;

        @include breakpoint(medium){
            margin-left: auto;
            flex: 0 0 calc(50% - 30px);
            width: 50%;
            max-width: 527px;
            padding: 137px 58px 49px;
            margin-top:6px;
        }

        @include breakpoint(1690px, "min"){
            margin-right: -100px;
        }

    }

    &__side-heading{
        margin: 0;
        @include font-size(32px, 34px);

        @include breakpoint(medium){
            @include font-size(60px, 70px);
        }
        
    }

    &__side-text{
        margin-top: 23px;
        @include breakpoint(medium){
            margin-top: 33px;
        }
    }
}