@import "../../shared-components-styles/article-common-styles.scss";

.article-page{
  &__categories{
    @include article-page-categories;
  }

  &__intro,
  &__body{
    color: $black;
    @include article-page-content;

    &-text{
      @include article-page-text;
    }
  }

  &__share-panel {
    @include container;
    max-width: $max-width-narrow;
    margin-bottom: $block-margin-bottom;
  }
}
