$panel-border-width: $border-width;
$panel-border-width-mobile: $border-width;

.tabs-panel {
    $self: &;
    margin-bottom: 40px;
    border-bottom: $panel-border-width-mobile solid $black;

    @include breakpoint(medium){
      margin-bottom: 60px;
      border-bottom-width: $panel-border-width;
    }

    &__list {
        @include container;
        max-width: $max-width-narrow;
        list-style: none;
    }

    &__item {
        position: relative;
        display: inline-block;
        padding: 4px 0;
        margin-top: 5px;
        font-size: 16px;

        @include breakpoint(medium){
            font-size: 32px;
            padding: 15px 0;
        }
        
        &:not(:last-child) {
            margin-right: 25px;

            @include breakpoint(medium){
                margin-right: 40px;
            }
        }

        &_active {
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: calc(100% + #{$panel-border-width-mobile});
                left: 0;
                right: 0;
                border-bottom: 4px solid $black;

                @include breakpoint(medium){
                    top: calc(100% + #{$panel-border-width});
                }
            }
        }
    }

    &__link {
        color: rgba($black, 0.5);
        text-decoration: none;

        #{$self}__item_active & {
            color: $black;
        }
    }
}

.tab-content {
    display: none;

    &_active {
        display: block;
    }
}