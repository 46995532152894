html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mouse-user * {
  outline: none;
}

html,
body {
	font-size: 100%;
	margin: 0;
	overflow: visible !important;
	background: $website-bg-color;
	height: 100%;
	@if $enable-font-smoothing == true {
		font-smooth: always;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
	}
}
html{
	overflow: hidden;
	-webkit-tap-highlight-color: transparent;
	line-height: 1em;
	&[dir="rtl"] { direction: rtl; }
}

body{
	-webkit-font-smoothing: antialiased;
	overflow-y: scroll !important;
	min-width: $min-width;
	font-family: $font-family;
	font-size: $font-size-em;
	font-weight: $font-weight-normal;
	line-height: $line-height;
	-webkit-overflow-scrolling: touch;
    color: $dark-blue;
	&.header-menu-is-opened{
		overflow: hidden !important;
		&:before {
			content: "";
			@include full-size;
			position: fixed;
			background: $white;
			z-index: 12;
			opacity: 0.5;
		}
	}
}

@page {
	margin-bottom: 50px;
}

.mouse-user *:focus{outline: none;}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.container{
	@include container;

  &_narrow{
    @extend .container;
    padding-left: 0;
    padding-right: 0;

    @include breakpoint(large) {
      padding-left: $spacing-narrow;
      padding-right: $spacing-narrow;
    }
  }
}

.clearfix {
	@extend %clear;
}

.block {
    overflow: hidden;
	width: 100%;
	margin: 0 0 $spacing-large;

	@include breakpoint(medium) {
		margin: 0 0 $spacing-xxlarge;
	}
}

.page {
	$self: &;
	&__root {
		width: 100%;
		display: table;
		height: 100vh;
		width: 100%;
		table-layout: fixed;

		.header-menu-is-opened &{
			overflow: hidden ;
			display: block;
		}

		&.on-page-editing {
			height: auto;
		}
	}

  &__header {
    width: 100%;
    top: 0;
    z-index: 10;
    color: $dark-blue;
	border-bottom: $border-width solid $black;

	@media print {
		border-bottom: none;
	}

    #{$self}__root.front-page &, #{$self}__root.page-not-found & {
        border-bottom: none;
    }
  }

  &__breadcrumbs {
	@media print {
		display: none;
	}
  }

  &__content{
	  margin: $breadcrumbs-height-mobile 0 0;
	  
	  @include breakpoint(medium) {
		margin-top: $breadcrumbs-height;
	  }

	  #{$self}__root.front-page &,
	  #{$self}__breabcrumbs + &{
		  margin-top: 0;
	  }
  }

  &__container {
    @extend .container;
  }

	&__footer{
		display: table-row;
		height: 1px;
		position: relative;
		z-index: 10;

		@media print {    
			display: none;
		}
	

		&:before{
			content: '';
			background: black;
			height: $border-width;
			width: 100%;
			display: block;

			#{$self}__root.technique-page &,
			#{$self}__root.front-page &{
				display: none;
			}
		}
	}
}

#skip-to-main {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 200;
	background: $black;
	display: flex;
	padding: 20px;
	color: $white;
	font-weight: bold;
	transition: transform 100ms ease-in-out, opacity 100ms linear;
	transform: translateY(-100%);
	outline: none;

	&:focus {
		transform: none;
	}

	@media print {
		display: none;
	}
}

.todo{
	position: relative;
	overflow: hidden;
	border: 1px solid red;
	padding: 0px;


	&:before{
		content: attr(data-todo-text);
		float: right;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-color: red;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAOCAMAAABuIH5JAAAAMFBMVEX///////////////////////////////////////////////////////////////9Or7hAAAAAD3RSTlMAESIzRFVmd4iZqrvM3e5GKvWZAAAAuUlEQVR4AbWRy47FMAhDyePekEfw///tQF1VqWY1i/Gisg7GqIr8kzYoE2nhR3ZD1HycNJxKqKkubFWV6Tk1WJERYEUi+bZ2YCUWD6zYiZqY7RssmDtUkQr0Mzo9w41K8HVnGHLN7IySRsOHwN11ifvpd7T4lKC8ouWIbkzhWba6e6L93dq5OWDpAXnzB7aDI5oNK4sCSuClKz5T0vRLd5SnP4ZQD0DV5zW+QmUtNE1V8wVCjTCs/E0/qlsMpWWH2kkAAAAASUVORK5CYII=);
		position: relative;
		z-index: 101;
		min-height: 16px;
		color: #fff;
		padding-left: 46px;
		font-size: 14px;
		line-height: 14px;
	}

	&:after{
		display: block;
		content: " ";
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: rgba( 255, 0, 0, 0.3);
	}
}

.hidden {
	display: none;
}

.LinesEllipsis {
	display: block;
}
