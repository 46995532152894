.link-button {
	display: inline-block;
	color: $black;
	@include font-size(18px, 20px);
	border: 2.5px solid $black;
	padding: 15px 20px;
	text-decoration: none;
	font-weight: 500;
	transition: all 0.5s ease-in-out;

	@include breakpoint(small) {
		@include font-size(26px, 30px);
		padding: 17px 23px;
	}

	&:hover,
    &:focus,
    .mouse-user &:focus:hover,
	&_active  {
        color: $white;
        background-color: $black;
        text-decoration: none;
    }

	&_light-hover {
		&:hover,
		&:focus,
		.mouse-user &:focus:hover {
			background-color: $white;
			color: $black;
		}
	}

    &:focus {
        .mouse-user &{
            background-color: $white;
            color: $black;
        }
    }
}