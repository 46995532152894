//------------------------------------
//  MIXINS
//------------------------------------
@use "sass:math";

@mixin hovered-border{
	position: relative;
	outline: none;

	&:before{
		opacity: 0;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		border: 0px solid $black;
		z-index: 2;
		transition: opacity 0.3s, border-width 0.2s;
	}
	
	&_selected,
	&:hover,
    &:focus{
		&:before{
            opacity: 1;
			border-width: $border-width;
        }
	}
}

@mixin stripedHeading($lineHeightMobile, $lineHeightDesktop, $borderMobile, $borderDesktop, $color) {
	position: relative;
	word-break: break-word;
	&:before{
		position: absolute;
		display: block;
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transform: rotate(180deg);
		background: linear-gradient($color $borderMobile, transparent 0);
		background-size: 100% convert-to-rem($lineHeightMobile);

		@include breakpoint(medium) {
			background: linear-gradient($color $borderDesktop, transparent 0);
			background-size: 100% convert-to-rem($lineHeightDesktop);
		}
	}
}

@mixin blured-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: "";
	display: block;
	background:$secondary-color;
	opacity: 0.5;
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);

	filter: brightness(0.4);
	-webkit-filter: brightness(0.4);
	-moz-filter: brightness(0.4);
	-o-filter: brightness(0.4);
	-ms-filter: brightness(0.4);
}

@mixin full-size {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
}

@mixin clear-styles{
	border: none;
	border-radius: 0;
	background: none;
	padding: 0;
	margin: 0;
}

@mixin flex-button{
	@include clear-styles;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	&-text,
	&-icon{
		align-self: center;
	}
}

/*Image sizes*/

@mixin object-fit($fit: cover, $position: null) {
	-o-object-fit: $fit;
	object-fit: $fit;
	@if $position {
		-o-object-position: $position;
		object-position: $position;
	}
	height: 100%;
	width: 100%;
	display: block;
	backface-visibility: hidden;
	transition: transform 0.35s;
}

@mixin fixed-proportion-image($width, $height) {
	height: 0px;
	padding-bottom: math.div($height, $width) * 100%;
	//background: none !important;
	position: relative;

	.image__img {
		@include object-fit(cover, center);
		position: absolute;
	}
}

@mixin fixed-proportion($width, $height) {
	padding-bottom: math.div($height, $width) * 100%;
}

@mixin fixed-height-image($width, $height) {
	height: ($height) + px;
	position: relative;
	background-position: center;
	background-size: cover;
	width: 100%;

	.image__img {
		@include object-fit(cover, center);
	}
}

@mixin fluid-image() {
	padding: 0px;
	@include full-size;
	background-position: center;
	background-size: cover;
	max-width: none;
	position: absolute !important;
    height: auto !important;

	.image__img {
		opacity: 0;
	}
}

@mixin original-proportions-image($width, $height) {
	padding: 0;
	height: auto;

	.image__img {
		position: relative;
		height: auto;
		width: auto;
		max-width: 100%;
	}

	&.image_in-ie .image__img {
		display: block;
	}
}

@mixin icon($iconClass) {
	@extend .icon !optional;
	@extend #{$iconClass};
}

@mixin container() {
	@extend %clear;
	overflow: visible;
	margin: 0 auto;
	width: 100%;
	max-width: $max-width;
	padding-left: $spacing-xmedium;
	padding-right: $spacing-xmedium;

	@include breakpoint(medium){
		padding-left: 20px;
	    padding-right: 20px;
	}
}

// Clearfix
// -------------------------

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}

@mixin cf() {
	*zoom: 1;
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin flex-centered($position: relative) {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin vertical-align($position: relative) {
	position: $position;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin horizontal-align($position: relative) {
	position: $position;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

@mixin center-align($position: relative) {
	position: $position;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow: inset $top $left $blur $color;
		-moz-box-shadow: inset $top $left $blur $color;
		box-shadow: inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

@mixin opacity($value) {
	$IEValue: $value * 100;
	opacity: $value;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $IEValue + ")";
	filter: alpha(opacity=$IEValue);
}

@mixin border-radius($radius) {
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

/*  Transition*/

@mixin transition($transition...) {
	-moz-transition: $transition;
	-o-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;
}
@mixin transition-property($property...) {
	-moz-transition-property: $property;
	-o-transition-property: $property;
	-webkit-transition-property: $property;
	transition-property: $property;
}
@mixin transition-duration($duration...) {
	-moz-transition-property: $duration;
	-o-transition-property: $duration;
	-webkit-transition-property: $duration;
	transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
	-moz-transition-timing-function: $timing;
	-o-transition-timing-function: $timing;
	-webkit-transition-timing-function: $timing;
	transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
	-moz-transition-delay: $delay;
	-o-transition-delay: $delay;
	-webkit-transition-delay: $delay;
	transition-delay: $delay;
}

/*  Transform*/

@mixin transform($transform...) {
	-ms-transform: $transform;
	-webkit-transform: $transform;
	transform: $transform;
}

//
// Uses `calculate-rem()` to calculate rem font-size and px
// fallback. line-height is calculated with `calculate-line-height()`
// but passing `false` will prevent that.
//
// Parameters:
//  $font-size: the font size (in pixels) to be converted to rem
//  $rem-sizing: if you want to convert the font-size to rem or not (default is true)
//  $line-height: set to false if you wish not to output a calculated line-height (defalt is true)
//
// Example:
//  `@include font-size(24px);`
//
//
// Big thanks to inuitcss for inspiration behind this
// (https://github.com/csswizardry/inuit.css/blob/master/generic/_mixins.scss)

@mixin responsive-font-size($mobile-size, $desktop-size) {
	font-size: convert-to-rem($mobile-size);

	@include breakpoint(small) {
	  font-size: convert-to-rem($desktop-size);
	}
  }

@mixin font-size($font-size,  $line-height: false) {
	font-size: $font-size;
    font-size: calculate-rem($font-size);

	@if $line-height {
		line-height:  $line-height;
		line-height:   calculate-rem($line-height);
	}
}

//
// Proper vendor prefixes are created by passing
// a property, property value, and browser
// vendor (webkit, moz, ms, o, etc).
//
// Parameters:
//  $property: what CSS property to generate vendor prefixes for
//  $value: the value of what was defined in `$property`
//  $vendors: what vendor prefixes to generate (default is none)
//
// Example:
//  `@include vendor(border-radius, 4px, webkit moz ms)`
//
//
@mixin vendor($property, $value, $vendors: "") {
	@each $vendor in $vendors {
		@if $vendor != "" {
			-#{$vendor}-#{$property}: $value;
		}
	}

	#{$property}: $value;
}

//
// Loops through all of the values in the `$breakpoints`
// map and outputs conditional statements used to generate
// media query code.
//
// When calling the mixin, if the parameter matches a key
// from the `breakpoints` map, a media query is output with
// that key's value. If an explicit value is set (ex: 360px)
// then a media query is output with that value.
//
// Parameters:
//  $point: the breakpoint value for the media query output
//  $min-max: set whether `min` or `max` is used (default is `min`)
//
// Example:
//  `@include breakpoint(extra-small) { ... }`
//
//  `@include breakpoint(360px, max) { ... }`
//
//
@mixin breakpoint($point, $min-max: "min", $dimension: "width") {
	@if type-of($point) == string {
		@each $breakpoint-name, $breakpoint-value in $breakpoint-map {
			@if $point == $breakpoint-name {
				@media (#{$min-max}-#{$dimension}: $breakpoint-value) {
					@content;
				}
			}
		}
	} @else {
		@media (#{$min-max}-#{$dimension}: $point) {
			@content;
		}
	}
}

//
// Generates property media queries for any CSS property,
// value, and set of breakpoints. Allows you to easily change
// property values based a set of breakpoints.
//
// Parameters:
//  $properties: what CSS property to output inside of the media queries (can have multiple)
//  $values: the value for each property (can have multiple)
//  $responsive-values: what breakpoints to generate media queries for
//  $use-available-mixins: whether or not to use mixin outputs for properties like `font-size` or `line-height` (default is true)
//
// Example:
//  @include responsive("font-size", 11px,
//    (
//      "small" : 12px,
//        450px : 13px,
//       1100px : 14px,
//      "large" : 15px,
//       1600px : 16px,
//    )
//  );
//
//
// Big thanks to Max Luster for a great article about chained media
// queries that created the majority of this mixin.
// (https://bugsnag.com/blog/responsive-typography-with-chained-media-queries)
@mixin responsive($properties, $values, $responsive-values, $use-available-mixins: true) {
	@each $property in $properties {
		@if $property == "font-size" and $use-available-mixins == true {
			#{$property}: $values;
			#{$property}: ($values / $base-font-size-pixel) * 1rem;
		} @else if $property == "line-height" and $use-available-mixins == true {
			#{$property}: ceil($values / $base-line-height) * ($base-line-height / $values);
		} @else {
			#{$property}: $values;
		}
	}

	@each $breakpoint, $value in $responsive-values {
		@if type-of($breakpoint) == string {
			@if (map-has-key($breakpoint-map, $breakpoint)) {
				$breakpoint: map-get($breakpoint-map, $breakpoint);
			} @else {
				$breakpoint: "null";
				@warn "Couldn't find breakpoint: " + $breakpoint;
			}
		}

		@if $breakpoint != "null" {
			@media (min-width: $breakpoint) {
				@each $property in $properties {
					@if $property == "font-size" and $use-available-mixins == true {
						#{$property}: #{$value};
						#{$property}: ($value / $base-font-size-pixel) * 1rem;
					} @else if $property == "line-height" and $use-available-mixins == true {
						#{$property}: ceil($value / $base-line-height) * ($base-line-height / $value);
					} @else {
						#{$property}: #{$value};
					}
				}
			}
		}
	}
}

//
// Generates CSS to wrap semantic columns
// in a row.
//
// Example:
//  `@include row()`
//
//
@mixin row() {
	width: 100%;

	&:after {
		clear: both;
		content: " ";
		display: table;
	}
}

//
// Generates CSS for semantic columns.
//
// Parameters:
//   $column: the number of this particular column (determines width)
//   $number-columns: number of columns in the row (default is `$column-number`)
//   $first-column: set to `true` if it's the first column in a row (default is false)
//   $use-gutters: set to `true` if you want column gutters (default is false)
//   $gutter-value: percentage value of the gutters to be applied (default is `$gutters` variable)
//
// Example:
//  `@include column(16, 4, false, true, 4)`
//
//
@mixin column(
	$column,
	$number-columns: $column-number,
	$first-column: false,
	$use-gutters: false,
	$gutter-value: strip-units($gutters)
) {
	background-clip: padding-box;

	@include breakpoint(medium) {
		@if $use-gutters == true {
			$gutter-size: percentage($gutter-value) * 0.01;
			$width-of-column: (100% - $gutter-size * ($number-columns - 1)) / $number-columns;

			float: left;
			@if $first-column == false {
				margin-left: $gutter-size;
			}
			width: $width-of-column * $column + $gutter-size * ($column - 1);
		} @else {
			float: left;
			width: percentage(100 / $number-columns * $column) * 0.01;
		}
	}
}

//
// Generates CSS for pushing a semantic column left or right.
//
// Parameters:
//   $option: set to `push` or `pull` to generate proper styles
//   $column: the column number
//   $number-columns: the number of columns in the row (default is $column-number)
//   $use-gutters: set to `true` if your column has gutters (default is false)
//   $gutter-value: percentage value of the gutters to be applied (default is `$gutters` variable)
//
// Example:
//  `@include push-pull(push, 4, 16, true)`
//
//
@mixin push-pull(
	$option,
	$column,
	$number-columns: $column-number,
	$use-gutters: false,
	$gutter-value: strip-units($gutters)
) {
	$property: "";

	@if $option == "push" {
		$property: "margin-left";
	} @else {
		$property: "right";
	}

	@if $use-gutters == true {
		#{$property}: math.div(100%, $number-columns) * $column - $gutter-value;
	} @else {
		#{$property}: math.div(100%, $number-columns) * $column;
	}
}

//
// Generates CSS that will clear both left
// and right floats.
//
// Example:
//  `@include clearfix()`
//
//
@mixin clearfix() {
	& {
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
}

//
// Create variable-number grid columns given the value
// for variable `$column-number`.
//
// NOTE:
//   This is a setup mixin for the Concise grid. If you
//   wish to set up a grid, please use the `row()` and
//   `column()` mixins.
//
@mixin grid-setup($number: $column-number) {
	@for $i from 1 through $number {
		$column-width-gutters: math.div(100% - $gutters * ($number - 1), $number);

		.#{$column-prefix + $i},
		.gutters .gutters-off .#{$column-prefix + $i} {
			width: math.div(100%, $number) * $i;
		}

		.gutters .#{$column-prefix + $i} {
			width: $column-width-gutters * $i + $gutters * ($i - 1);
		}
	}
}

//
// Create `.push-` and `.pull-` classes given
// the value for variabls `$option` and
// `$column-number`.
//
// NOTE:
//   This is a setup mixin for the push and pull
//   functionality in the Concise grid. If you wish
//   to use that functionality, please use the
//   `push-pull()` mixin.
//
@mixin push-pull-setup($option, $number: $column-number) {
	$property: "";

	@if $option == "push" {
		$property: "left";
	} @else {
		$property: "right";
	}

	@for $i from 1 to $number {
		.#{$option}-#{$i} {
			#{$property}: math.div(100%, $number) * $i;
		
			.gutters & {
				#{$property}: math.div(100%, $number) * $i - $gutters;
			}
		}
	}
}

@mixin visually-hidden {
	position: absolute;
	left: -999em;
	clip: rect(0, 0, 0, 0);
	opacity: 0;
	overflow: hidden;
}

@mixin carousel-ipad-pro-view {
    @media screen and (min-width: 480px) and (max-width: $ipad-pro-breakpoint) {
        @content;
    }
}

@mixin image-hover-zoom{
	transform: scale(1.1);
}

@mixin reset-search-input {
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		-webkit-appearance:none;
	}
}