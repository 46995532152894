.related-items-haeder {
    $self: &;
    @include container;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @include breakpoint(medium) {
        margin-bottom: 50px;
    }

    &__heading,
    &__see-all-button{
        align-self: flex-start;
    }

    &__heading {
        margin: 0;
        @include font-size(40px, 45px);

        @include breakpoint(medium) {
            @include font-size(50px, 60px);
        }
    }

    &__see-all-button {
        margin-left: auto;
        margin-top: -13px;
    }
}
