.icon-label {
   $self: &;
   display: flex;
   color: $black;

   @include font-size(16px, 16px);

   @include breakpoint(medium) {
      @include font-size(24px, 24px);

      &_small {
         @include font-size(16px, 16px);
      }
   }

   &__icon {
      align-self: center;
      width: 14px;
      height: 14px;
      display: block;
      margin-top: -2px;
      margin-right: $spacing-medium;

      #{$self}_small & {
         margin-right: $spacing-small;
      }

      @include breakpoint(medium) {
         width: 25px;
         height: 25px;
         margin-top: -1px;

         #{$self}_small & {
            width: 20px;
            height: 20px;
         }
      }

      @media print {
         .recipe-page & {
            width: 20px;
            height: 20px;
         }
      }
  }

   &__text {
      align-self: center;
      display: block;

      &_hidden {
         @include visually-hidden;
      }
   }
}