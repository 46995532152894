.technique-page {
  $self: &;

  

  &__group-heading {
    display: block;
    @include container;
    border: none;
    font-family: $font-family-secondary;
    font-weight: normal;
    color: $black;
    margin-bottom: 21px;
    padding-top: 23px;
    letter-spacing: 0.2px;
    @include font-size(40px, 45px);

    @include breakpoint(medium) {
        padding-top: 15px;
        margin-bottom: 42px;
        letter-spacing: 0.18px;
        @include font-size(70px, 80px);
    }
  }

  &__heading{
      @include container;
      letter-spacing: 0.2px;

      @include font-size(32px, 38px);
        margin-bottom: 17px;
      
      @include breakpoint(medium) {
        @include font-size(50px, 60px);
      margin-bottom: 39px;
      }
  }
  
  &__intro {
    @include container;
    margin-bottom: 11px;
    color: $black;
    @include breakpoint(medium) {
      margin-bottom: 56px;
    }

    &-text {
      max-width: $max-text-width;
    } 
  }

  &__navigation{
    &_mobile-only{
      @include container;
      margin-bottom: 20px;

      @include breakpoint(medium) {
        display: none;
      }
    }
    &_desktop-only{
      display: none;

      @include breakpoint(medium) {
        display: block;
      }
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;
    @include container;
    margin-bottom: $block-margin-bottom-mobile;
    padding-top: 19px;

    @include breakpoint(medium) {
      padding-top: 32px;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-bottom: $block-margin-bottom;
    }
  }

  &__column {
    margin-bottom: 0px;
    &:first-child {
      @include breakpoint(medium) {
        flex: 0 0 260px;
        margin-right: 40px;
        margin-bottom: 0;
        align-self: center;
      }
    }
    &:last-child {
      @include breakpoint(medium) {
        margin-left: auto;
        flex: 0 0 calc(100% - 300px);
        align-self: flex-start;
        max-width: 1260px;
        overflow: hidden;
        margin-bottom: 0px;
      }
    }
  }
}
