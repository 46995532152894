@mixin article-page-categories() {
    @include container;
    margin-bottom: 30px;
    max-width: $max-width-narrow;
  
    @include breakpoint(medium){
      margin-bottom: 40px;
    }
}
  
@mixin article-page-content {
    @include container;
    margin-bottom: 40px;
    max-width: $max-width-narrow;
  
    @include breakpoint(medium){
      margin-bottom: 80px;
    }
}
  
@mixin article-page-text {
    @include breakpoint(medium){
      max-width: $max-text-width;
    }
}