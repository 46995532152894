$pager-link-size: 50px;

@mixin pager-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $pager-link-size;
    height: $pager-link-size;
    color: $black;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus-visible {
        text-decoration: underline;
    }
}

.pager {
    $self: &;
    display: inline-flex;
    position: relative;

    &__list {
        display: inline-flex;
        align-items: center;
        list-style: none;
    }

    &__break {
        display: none;
        margin-right: 12px;

        a {
            @include pager-link;
            width: 30px;
        }

        @include breakpoint(medium) {
            display: block;
        }
    }
        
    &__item {
        font-size: 22px;
        display: none;

        &:not(:last-child) {
            margin-right: 10px;
        }

        @include breakpoint(medium) {
            display: block;
        }
    }

    &__link {
        position: relative;
        @include pager-link;
        transition: all 200ms;
        z-index: 0;

        &:before {
            content: '';
            @include full-size;
            border: 2px solid transparent;
            z-index: 0;
            transition: all 200ms;
        }

        #{$self}__item.selected & {
            font-weight: $font-weight-bold;
            color: $black;

            &:before {
                border-color: $black;
            }
        }
    }

    &__prev,
    &__next {
        font-size: 20px;

        @include breakpoint(medium) {
            font-size: 22px;
        }

        a {
            position: relative;
            @include pager-link;
            font-weight: $font-weight-bold;

            &:before {
                content: '';
                width: 23px;
                height: 20px;
                @include center-align(absolute);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }

            &:hover,
            &:active,
            &:focus-visible {
                text-decoration: underline;
            }
        }

        &.disabled {
            filter: grayscale(1);
            opacity: 0.2;
        }
    }

    &__prev {
        margin-right: 10px;

        a {
            text-indent: -9999px;
            font-size: 0;
    
            &:before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMS45NyAyNy45NyI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZD0iTTIuOTcgMTIuNjFoMjl2My42aC0yOXoiLz48cGF0aCBmaWxsPSIjMDAwMDAwIiBkPSJNLS4wMDggMTMuOTk4IDEzLjk5My0uMDAzbDIuNTQ2IDIuNTQ1TDIuNTM4IDE2LjU0M3oiLz48cGF0aCBmaWxsPSIjMDAwMDAwIiBkPSJtMS4yMTggMTUuMTcyIDIuNTQ2LTIuNTQ2IDEyLjc5OSAxMi43OTktMi41NDYgMi41NDV6Ii8+PC9zdmc+");  
            }
        }
    } 

    &__next {
        margin-left: 20px;

        a {
            width: auto;
            flex-direction: row-reverse;
    
            &:before {
                display: inline-block;
                margin-left: 10px;
                position: static;
                transform: none;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDMzIDI4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMyAyODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KICAgIDxyZWN0IHg9IjAiIHk9IjExLjgiIGZpbGw9IiMwMDAwMDAiIHdpZHRoPSIyOSIgaGVpZ2h0PSIzLjYiLz4NCiAgICA8cmVjdCB4PSIxMy44IiB5PSIxNy45IiB0cmFuc2Zvcm09Im1hdHJpeCgwLjcwNzEgLTAuNzA3MSAwLjcwNzEgMC43MDcxIC02Ljk5MTMgMjIuNTYzMykiIGZpbGw9IiMwMDAwMDAiIHdpZHRoPSIxOS44IiBoZWlnaHQ9IjMuNiIvPg0KICAgIDxyZWN0IHg9IjIxLjMiIHk9Ii0xLjQiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzA3MSAtMC43MDcxIDAuNzA3MSAwLjcwNzEgMS4zNCAxOC42MzE4KSIgZmlsbD0iIzAwMDAwMCIgd2lkdGg9IjMuNiIgaGVpZ2h0PSIxOC4xIi8+DQo8L3N2Zz4NCg==");
            }
        }
    } 

    &__mobile-selector {
        padding: 12px;
        border: 2px solid $black;
        min-width: 108px;
        font-family: $font-family;
        font-size: 22px;
        color: $black;
        background: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        text-decoration: underline;
        text-align: center;

        @include breakpoint(medium) {
            display: none;
        }

        &:active {
            background-color: $black;
            color: $white;
        }
    }
}