@import "../../shared-components-styles/related-items.scss";
.related-recipes-block {
    @extend .related-items;

    &__container{
        @extend .related-items-container;
    }

    &__see-all-button{
        @extend .related-items-see-all-button;
    }
    
    &__teasers {
        @include container;
        overflow: hidden;
        padding-bottom: 55px;
    }
    &__teasers-list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        list-style: none;
        align-content: stretch;
        width: calc(100% + 40px);
        margin-right: -40px;
    }

    &__teasers-item{
        width: calc((100% / 2) - 40px);
        margin-right: 40px;
        margin-bottom: 60px;

        @include breakpoint(large) {
            width: calc(25% - 40px);
        }
    }

    &__teaser{
        min-height: 100%;
    }
}