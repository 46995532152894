.tags-filter-item {
    @include clear-styles;
    display: block;
    padding: 9px 8px;
    @include font-size(16px, 16px);
    font-weight: $font-weight-bold;
    cursor: pointer;
    position: relative;

    @include breakpoint(medium){
        padding: 14px 12px;
        @include font-size(24px, 24px);
    }

    @include hovered-border;

    &_selected{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
    }

    &__icon{
        align-self: center;
        margin-right: 10px;
    }

    &__text{
        align-self: center;
    }
}
