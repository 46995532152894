//------------------------------------
//  BASE
//------------------------------------
$website-bg-color: #fff !default;


// Enable font smoothing
$enable-font-smoothing: true !default;


// Base font sizes and colors

$tera-size: 53px !default;
$giga-size: 28px !default;
$extra-large-size: 20px !default;
$large-size: 18px !default;
$medium-size: 16px !default;
$small-size: 14px !default;
$extra-small-size: 12px !default;



//------------------------------------
//  COLORS
//------------------------------------



$white: white;
$black: black;
$maincolor: $black;

$dark-blue: #03123f;
$secondary-color: $dark-blue;


$orange: #FFB063;
$dark-orange: #EF563F;
$light-orange: #F9D7B5;
$green: #A2FF93;
$light-green: #D9FDCD;
$coral: #FF8977;
$light-coral: #F6C6BC;
$yellow: #FFEA79;
$light-yellow: #FDF4C1;
$blue: #C5F6FF;
$light-blue: #E5F8FE;
$beige: #F2E9D8;
$light-beige: #F7F4EC;
$dark-gray: #03123F80;
$gray: #7f7f7f;
$medium-gray: #959595;
$light-gray: #d0d0d0;
$purple: #3f48cc;
$dark-purple: #1923b3;

//-----------------------------------------------------
// Calculation variables
//-----------------------------------------------------
$base-font-size: 16px !default; // WARN! Never change - it is a constans needed for calculations.
$base-line-height: $extra-large-size !default;
$line-height-ratio: calc($base-line-height / $base-font-size);
//-------------------------------------------------------

$font-family: 'Banana Groteskt', Arial, sans-serif;
$font-family-secondary: 'Roslindale', Helvetica, sans-serif;
$font-size-em : #{calc($extra-large-size / $base-font-size) * 1em};
$font-weight-normal: normal;
$font-weight-medium: 500;
$font-weight-bold: bold;
$print-font-family: Georgia, "Times New Roman", Times, serif !default;

$line-height: $giga-size;

$font-color: $maincolor;
$link-color: $dark-blue;
$link-hover-color: $dark-blue;

$validation-color: red;


//------------------------------------
// Spacing
//------------------------------------

$spacing-small: 5px;
$spacing-medium: 10px;
$spacing-xmedium: 20px;
$spacing-large: 40px;
$spacing-xlarge: 30px;
$spacing-xxlarge: 90px;
$spacing-giga: 140px;
$spacing-narrow: 220px;

$content-width: 1640px;

$max-width: calc(#{$content-width} + #{$spacing-xmedium * 2});
$max-width-narrow: calc(#{$max-width} - 200px);
$max-text-width: 800px;
$min-width: 320px;

$header-height: 110px;
$header-height-medium: 160px;
$header-menu-padding: 105px;
$header-menu-padding-mobile: 50px;

$breadcrumbs-height: 76px;
$breadcrumbs-height-mobile: 48px;

$block-margin-bottom: 100px;
$block-margin-bottom-mobile: 40px;
$block-margin-bottom-in-accordion: 40px;

$font-size-scaling-breakpoint: 1680px;

$teaser-shadow: 0px 3px 6px #00000029;

$border-width: 2px;

//------------------------------------
//  RESPONSIVE
//------------------------------------

$ipad-pro-breakpoint: 1025px;

// Breakpoints
$breakpoint-map: (
  "extra-small": 30em, //480px
  "extra-small-max": 47.999em,
  "small":       48em, //ipad-min
  "small-max": 63.999em,
  "medium":      64em, //ipad-max
  "medium-max": 79.999em,
  "large":       80em, //1280px
  "extra-large": 100em,//1600 px
  "container-max": $max-width, //1680px centered container
  "ipad": (min-device-width 768px) (max-device-width 1024px),
  "ipad-pro": $ipad-pro-breakpoint,
  "iphone": (min-device-width 320px) (max-device-width 480px),
  "iphone5": (min-device-width 320px) (max-device-width 568px)
);

// Selection colors
$selection-background:  $blue;
$selection-color:       $maincolor !default;


// Form placeholder colors
$placeholder-color:     #bbbbbb !default;


// Colors used for our objects' borders, etc
$object-ui-color:       #329C42 !default;


// Dropdown colors
$dropdown-bg-color:     #fffa37 !default;
$dropdown-border-color: #ededed !default;
$dropdown-hover-color:  #f3f8f8 !default;
$dropdown-header-color: #aaaaaa !default;



// Button
$button-font-color: $maincolor !default;
$button-hover-font-color: $white;


//------------------------------------
//  Components
//------------------------------------

// Gutter size
$gutters: 2% !default;


// Prefix for grid columns. By default columns
// are formatted as `.column-8`
$column-prefix: 'column-' !default;


// Dictate how many columns you would like your
// grid system to use. Default is 12.
$column-number: 16 !default;

$use-grid:           true !default;
$use-push:	       true !default;
$use-pull:	       true !default;
