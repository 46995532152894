.carousel {
    width: 100%;
    clear: both;

    &__container{
        width: calc(100% + 40px);
        margin-right: -20px;
        margin-left: -20px;
    }

    &__items{
        display: flex;
        flex-wrap: nowrap;
        padding: 0 0 40px;
        
        &:after{
            content: '';
            width: 20px;
            flex: 0 0 30px;
            display: block;
        }
    }

    &__item{
        width: calc(100% - 60px);
        flex: 0 0 calc(100% - 60px);
        margin-left: 20px;

        @include carousel-ipad-pro-view {
            flex: 0 0 300px;
            width: 300px;
        }

        &:first-child{
            margin-left: 30px;
        }
    }
    &__teaser{
        height: 100%;
    }

    &__scrollbar-track{
        border-radius: 0 !important;
        background: none !important;
        height: 7px !important;
        width: calc(100% - 60px) !important;
        left: 30px !important;

        &:before{
            content: "";
            display: block;
            height: 3px;
            background: black;
            width: 100%;
            position: absolute;
            top: 2px;
            left: 0;
        }
    }
    &__scrollbar-thumb{
        background: black !important;
        border-radius: 0 !important;
    }
}