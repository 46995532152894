$top-tag-rotation-degree: 30deg;
$top-tag-hover-rotation-degree: 30deg;
$bottom-tag-rotation-degree: 25deg;
$bottom-tag-hover-rotation-degree: 10deg;
$tag-indent: 15px;

.floating-tags {
    $self: &;
    display: inline-flex;
    position: relative;
    &__items {
        list-style: none;
        @include breakpoint(container-max, "max") {
            #{$self}_in-accordion & {
                display: none;
            }
        }
    }

    &__item {
        display: inline-block;
        position: absolute;
        transition: transform 300ms;

        &_top {
            top: -35px;
            transform-origin: 70px center;

            &-left {
                left: $tag-indent;
                transform: translate(-50%, 0) rotate(-#{$top-tag-rotation-degree});
                &:hover {
                    transform: translate(-50%, 0) rotate(#{$top-tag-hover-rotation-degree});
                }
            }
            &-center {
                left: 50%;
                transform: translate(-50%, 0) rotate(-#{$top-tag-rotation-degree});
                &:hover {
                    transform: translate(-50%, 0) rotate(#{$top-tag-hover-rotation-degree});
                }
            }
            &-right {
                right: $tag-indent;
                transform: translate(50%, 0) rotate(-#{$top-tag-rotation-degree});
                &:hover {
                    transform: translate(50%, 0) rotate(#{$top-tag-hover-rotation-degree});
                }

                #{$self}_in-side-panel &{
                    transform: translate(50%, 0) rotate(7deg);
                    right: 40px;
                    
                    @include breakpoint(medium){
                        top: -60px;
                    }
                    &:hover {
                        transform: translate(50%, 0) rotate(-#{$top-tag-hover-rotation-degree});
                    }
                }

            }
        }

        &_bottom {
            bottom: -20px;
            transform-origin: center bottom;
            
            &-left {
                left: $tag-indent;
                transform: translate(-50%, 0) rotate(#{$bottom-tag-rotation-degree});
                &:hover {
                    transform: translate(-50%, 0) rotate(-#{$bottom-tag-hover-rotation-degree});
                }
            }
            &-center {
                left: 50%;
                transform: translate(-50%, 0) rotate(#{$bottom-tag-rotation-degree});
                &:hover {
                    transform: translate(-50%, 0) rotate(-#{$bottom-tag-hover-rotation-degree});
                }
            }
            &-right {
                right: $tag-indent;
                transform: translate(50%, 0) rotate(#{$bottom-tag-rotation-degree});
                &:hover {
                    transform: translate(50%, 0) rotate(-#{$bottom-tag-hover-rotation-degree});
                }
            }
        }
    }
}