.tags-filter {
    $self: &;
    @include container;
    list-style: none;
    margin-top: 0px;
    margin-bottom: 82px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include breakpoint(medium) {
        margin-bottom: 90px;
    }

    &_in-recipes-overview {
        margin: 0;
        padding: 0;
    }

    &__item {
        margin: 0 20px 15px 0;

        @include breakpoint(medium) {
            margin: 0 20px 20px 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &-button {
            #{$self}_has-selected & {
                opacity: 0.5;

                &:focus,
                &:hover {
                    opacity: 1;
                }
            }

            #{$self}_has-selected #{$self}__item_selected & {
                opacity: 1;
            }
        }
    }
}
