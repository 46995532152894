@use "sass:math";

.scroll-button {
    $self: &;
    overflow: hidden;
    border: none;
    cursor: pointer;
    background: none;
    padding: 0;
    z-index: 10;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;

    .mouse-user &:focus {
        outline: none;
    }

    &__text {
        align-self: center;
        @include font-size(14px, 22px);
        white-space: nowrap;
        margin-right: math.div($spacing-xlarge, 2);
        color: $dark-blue;

        @include breakpoint(small) {
            @include font-size(20px, 22px);
        }

        @include breakpoint(large) {
            margin-right: $spacing-xlarge;
        }

        #{$self}:focus &,
        #{$self}:hover &{
            text-decoration: underline;
        }
    }

    &__icon {
        width: 15px;
        height: 22px;
        align-self: center;
        color: $black;
    }
}
