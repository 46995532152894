.not-found-page {
    margin-bottom: 95px;

    @include breakpoint(medium){
        margin-bottom: 185px;
    }

    &__container {
        @include container;
        max-width: $max-text-width;
        text-align: center;
    }

    &__heading-container {
        position: relative;
        display: inline-block;
    }

    &__heading {
        @include font-size(184px, 184px);
        font-family: $font-family-secondary;
        color: $dark-blue;
        margin: 0 0 25px;

        @include breakpoint(medium){
            @include font-size(289px, 289px);
            margin: 0 0 55px;
        }
    }

    &__colored-tag {
        position: absolute;
        
        &_left {
            top: 30%;
            left: 35px;
            transform: translateX(-50%) rotate(7deg);
        }

        &_right {
            top: 40%;
            right: 25px;
            transform: translateX(50%) rotate(-20deg);
        }
    }

    &__text {
        max-width: 628px;
        margin: 0 auto 40px;

        @include breakpoint(medium){
            margin: 0 auto 55px;
        }
    }

    &__btn {
        margin-top: 35px;

        @include breakpoint(medium){
            margin-top: 50px;
        }
    }
}