$midnight-blue: #011E64;
.loading-indicator {
    @include full-size;
    background-color: $white;
    opacity: 0.5;
    z-index: 20;

  &:before {
    content: "";
    display: block;
    margin: 20px auto;
    font-size: 15px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0) scale(0.5);
    -ms-transform: translateZ(0) scale(0.5);
    transform: translateZ(0) scale(0.5);

  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
  }


  @-webkit-keyframes load5 {

    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em $midnight-blue, 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.5), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.7);
    }

    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.7), 1.8em -1.8em 0 0em $midnight-blue,
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.5);
    }

    25% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.5), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.7),
        2.5em 0em 0 0em $midnight-blue, 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.5),
        2.5em 0em 0 0em rgba($midnight-blue, 0.7), 1.75em 1.75em 0 0em $midnight-blue,
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    50% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.5), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.7),
        0em 2.5em 0 0em $midnight-blue, -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.5),
        0em 2.5em 0 0em rgba($midnight-blue, 0.7), -1.8em 1.8em 0 0em $midnight-blue,
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    75% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.5), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.7),
        -2.6em 0em 0 0em $midnight-blue, -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.5),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.7), -1.8em -1.8em 0 0em $midnight-blue;
    }
  }

  @keyframes load5 {

    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em $midnight-blue, 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.5), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.7);
    }

    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.7), 1.8em -1.8em 0 0em $midnight-blue,
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.5);
    }

    25% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.5), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.7),
        2.5em 0em 0 0em $midnight-blue, 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.5),
        2.5em 0em 0 0em rgba($midnight-blue, 0.7), 1.75em 1.75em 0 0em $midnight-blue,
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    50% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.5), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.7),
        0em 2.5em 0 0em $midnight-blue, -1.8em 1.8em 0 0em rgba($midnight-blue, 0.2),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.5),
        0em 2.5em 0 0em rgba($midnight-blue, 0.7), -1.8em 1.8em 0 0em $midnight-blue,
        -2.6em 0em 0 0em rgba($midnight-blue, 0.2), -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    75% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.5), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.7),
        -2.6em 0em 0 0em $midnight-blue, -1.8em -1.8em 0 0em rgba($midnight-blue, 0.2);
    }

    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($midnight-blue, 0.2), 1.8em -1.8em 0 0em rgba($midnight-blue, 0.2),
        2.5em 0em 0 0em rgba($midnight-blue, 0.2), 1.75em 1.75em 0 0em rgba($midnight-blue, 0.2),
        0em 2.5em 0 0em rgba($midnight-blue, 0.2), -1.8em 1.8em 0 0em rgba($midnight-blue, 0.5),
        -2.6em 0em 0 0em rgba($midnight-blue, 0.7), -1.8em -1.8em 0 0em $midnight-blue;
    }
  }
}