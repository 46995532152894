.teasers-list-advanced {
  position: relative;
    &__view-select{
        @include container;
        justify-content: flex-end;
        margin-bottom: 30px;
      }

      &__two-columns-view{
        @include container;
      }
       
      &__two-columns-view-item{
        padding-bottom: 30px;
      }
    
      &__load-more-button{
        margin-bottom: 40px;
        @include breakpoint(medium) {
          margin-bottom: 100px;
        }
      }
}