.header-menu {
    $self: &;

    @media print {
        display: none;
    }

    &__toggle-button {
        @include clear-styles;
        position: absolute;
        z-index: 22;
        top: 50%;
        right: 33px;
        margin: -13px 0 0;
        cursor: pointer;
        display: flex;
        height: 26px;

        @include breakpoint(medium) {
            right: 25px;
        }

        &-text {
            display: none;
            @include font-size(20px, 26px);
            font-family: $font-family;
            align-self: center;

            #{$self}_opened & {
                opacity: 1;
                @include breakpoint(medium, "max") {
                    display: block;
                }
            }

            @include breakpoint(medium) {
                @include font-size(26px, 26px);
                display: block;
                opacity: 0.5;

                .no-touchevents #{$self}__toggle-button:hover &,
                .no-touchevents #{$self}__toggle-button:focus & {
                    opacity: 1;
                    text-decoration: underline;
                }
            }
        }
        &-icon {
            display: block;
            align-self: center;
            width: 20px;
            margin-left: 20px;
        }
    }

    &__panel {
        background: $white;
        position: fixed;
        top: 0;
        right: -100%;
        height: 100%;
        z-index: 20;
        padding-top: $header-height;
        transition: right 0.7s ease-in-out;
        width: 100%;

        @include breakpoint(medium) {
            padding-top: $header-height-medium;
            width: calc(50vw - 50px);
            min-width: 730px;
        }

        @include breakpoint(1300px, "min") {
            width: calc(50vw - 200px);
        }

        &-content{
            overflow-x: hidden;
            overflow-y: auto;
            padding-bottom: 55px;
            display: flex;
            flex-direction: column;
            height: calc(100% - #{$header-height});
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            border-top: $border-width solid $black;

            @include breakpoint(medium) {
                border-top-width: $border-width;
                    .front-page &{
                        border-top-width: $border-width;
                    }
                padding-bottom: 70px;
                height: calc(100% - #{$header-height-medium});
            }
        }

        &_opened {
            right: 0;
        }
    }

    &__main-section {
        margin: 0;
        list-style: none;

        @include breakpoint(medium) {
            margin: 0 0 30px;
        }

        &-item {
            border-bottom: $border-width solid $black;
            position: relative;
            @include font-size(50px, 78px);

            @include breakpoint(medium) {
                @include font-size(70px, 80px);
            }
        }

        &-icon {
            @include vertical-align(absolute);
            left: 10px;
            opacity: 0;
            transition: all 0.2s ease-in-out;

            @include breakpoint(medium) {
                left: 40px;
            }

            .no-touchevents #{$self}__main-section:hover &,
            #{$self}__main-section:focus &  {
                opacity: 0;
                left: 10px;
                @include breakpoint(medium) {
                    left: 40px;
                }
            }

            #{$self}__main-section-link_active + &,
            .no-touchevents #{$self}__main-section-link:hover + &,
            #{$self}__main-section-link:focus + & {
                opacity: 1;
                left: 20px;
                @include breakpoint(medium) {
                    left: 50px;
                }
            }
        }

        &-link {
            display: block;
            padding: 0 $header-menu-padding-mobile;
            text-decoration: none;
            font-family: $font-family-secondary;
            font-weight: normal;
            color: $dark-blue;

            .no-touchevents #{$self}__main-section:hover &,
            #{$self}__main-section:focus &,
            #{$self}__main-section_focused &{
                opacity: 0.5;
            }

            &:hover,
            &:focus {
                opacity: 1;
            }

            .no-touchevents #{$self}__main-section:hover &:hover,
            #{$self}__main-section_focused &:focus{
                opacity: 1;
            }

            @include breakpoint(medium) {
                padding: 8px $header-menu-padding;
            }
        }
    }

    &__colored-tags {
        margin-top: 42px;
        padding: 0 $header-menu-padding-mobile;

        @include breakpoint(medium) {
            margin-top: 30px;
            padding: 0 20px 0 $header-menu-padding;
        }
    }

    &__bottom-group {
        margin-top: auto;
        padding: 0 $header-menu-padding-mobile;
        @include breakpoint(medium) {
            padding: 0 20px 0 $header-menu-padding;
        }
    }

    &__section {
        display: flex;
        flex-direction: row;
        @include font-size(14px, 24px);
        @include breakpoint(medium) {
            @include font-size(20px, 22px);
        }

        &-heading {
            margin: 0;
            font-family: $font-family;
            color: $black;
            font-size: inherit;
            line-height: inherit;
            opacity: 0.5;
            min-width: 87px;
            padding-right: 10px;
            margin-top: 5px;

            @include breakpoint(medium) {
                min-width: 115px;
                margin-top: 15px;
            }
        }

        &-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            list-style: none;
        }

        &-item {
            margin-top: 5px;
            margin-right: 10px;

            @include breakpoint(medium) {
                margin-top: 15px;
                margin-right: 15px;
            }
        }

        &-link {
            font-size: inherit;
            line-height: inherit;
            color: $dark-blue;
            text-decoration: underline;

            &:focus,
            &:hover {
                text-decoration: none;
            }

            @include breakpoint(medium) {
                text-decoration: none;
                &:focus,
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__search-trigger {
        position: absolute;
        display: flex;
        align-items: center;
        top: 35px;
        left: 15px;
        background: none;
        font-family: $font-family;
        border: none;
        padding: 5px;
        cursor: pointer;

        &-text {
            margin-left: 20px;
            font-size: 20px;
        }

        @include breakpoint(medium) {
            display: none;
        }
    }
}
