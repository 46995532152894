.top-image-block {
    $self: &;
    padding-bottom: 15px;

    @media print {
        display: none;
    }
    
    @include breakpoint(medium){
        margin-bottom: 0px;
        padding-bottom: 60px;
    }

    &__container{
        @include container;
        padding-left: 0;
        padding-right: 0;
        position: relative;

        &_in-article {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__images{
        width: 100%;
        position: relative;

        @include breakpoint(small){
            &:before{
                content: '';
                display: block;
                width: 100%;
                @include fixed-proportion(1640, 810);
            }
        }
    }

    &__image{
        #{$self}_two-images &{
            @include breakpoint(small){
                position: absolute;
                top: 0;
                left:0;
                width: 50%;
                height: 100%;

                & + #{$self}__image {
                    right: 0;
                    left: auto;
                }
            }
        }

        @include breakpoint(small){
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__image-caption{
        color: $black;
        opacity: 0.5;
        @include font-size(12px, 16px);
        margin: 10px 20px 0;
        text-align: right;

        #{$self}__container_in-article & {
            margin: 10px 0 0;
        }

        @include breakpoint(medium){
            @include font-size(14px, 16px);
            margin: 0;
            height: 0px;
            overflow: visible;
            padding-top: 10px;
        }
        
        @media all and (min-width: 1840px){
            position: absolute;
            left: 100%;
            top: 0;
            z-index: 10; height: 810px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 20px;
            writing-mode: vertical-rl;
            text-orientation: mixed;
            text-align: center;
            margin-left: 40px;
            margin-top: 0;
        }
    }

    &__high-res-photo-link {
        &-holder {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        display: inline-block;
        padding: 10px 16px;
        background-color: $purple;
        color: $white;
        @include font-size(18px, 24px);
        text-transform: uppercase;
        text-decoration: none;

        @include breakpoint(small) {
            padding: 12px 20px;
            @include font-size(24px, 30px);
        }

        @include breakpoint(medium) {
            padding: 17px 30px;
            @include font-size(42px, 50px);
        }

        &:hover,
        &:focus{
            background-color: $dark-purple;
        }
    }
}