.search-teaser {
    $self: &;
    @extend .recipe-teaser;

    &__image{
        transition: transform 300ms;
        #{$self}:hover &{
		    @include image-hover-zoom;
        }

        &-container{
            overflow: hidden;

            &_no-image{
                @extend .image_size_search-teaser;                
            }
        }

    }

    &__heading{
        @extend .recipe-teaser__heading;
    }

    &__details{
        @extend .recipe-teaser__details;
    }

    &__icon {
        @extend .recipe-teaser__icon;
    }
}
