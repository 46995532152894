.colored-tags-list {
    $self: &;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    &__item {
        margin-right: 10px;
        margin-bottom: 10px;
        @include breakpoint(medium) {
            margin-right: 20px;
            margin-bottom: 20px;
        }

        &-link {
            #{$self}_has-active & {
                opacity: 0.5;

                &:hover,
                &:focus {
                    opacity: 1;
                }
            }

            #{$self}_has-active &_active {
                opacity: 1;
            }
        }
    }
}
