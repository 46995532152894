a {
	color: $dark-orange;
	text-decoration: underline;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

p {
	margin: 0 0 convert-to-rem(26px);
	font-size: inherit;
	line-height: inherit;

	@include breakpoint(medium) {
		margin-bottom: convert-to-rem(36px);
	}

	&:last-child {
		margin: 0;
	}
}

h1,
h2,
h3,
h4 {
	font-family: $font-family-secondary;
	font-weight: normal;
	color: $black;
	margin: 20px 0;
	@include breakpoint(medium) {
		margin: 40px 0;
	}
}

h1, .h1 {
	@include font-size(60px, 60px);
	letter-spacing: -1.4px;
	@include breakpoint(medium) {
		@include font-size(140px, 140px);
	}
}

h2, .h2 {
	@include font-size(40px, 45px);
	@include breakpoint(medium) {
		@include font-size(70px, 80px);
	}
}

h3, .h2 {
	@include font-size(32px, 38px);

	@include breakpoint(medium) {
		@include font-size(50px, 60px);
	}
}

h4, .h4 {
	@include font-size(28px, 33px);
	@include breakpoint(medium) {
		@include font-size(40px, 50px);
	}
}

h5, .h5 {
	@include font-size(20px, 28px);
	font-family: $font-family;
	font-weight: normal;
	@include breakpoint(medium) {
		@include font-size(32px, 42px);
	}
}

img {
	max-width: 100%;
	display: block;
}

strong,
b {
	font-weight: bold;
}

em,
i {
	font-style: italic;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
	margin: $spacing-large 0;
}

td,
th {
	vertical-align: top;
	border: 1px solid $black;
	padding: $spacing-medium;
}

mark {
	background-color: $selection-background;
}
